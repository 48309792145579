import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  UserIcon,
  NewspaperIcon,
  BriefcaseIcon,
  UsersIcon,
  ChartBarIcon,
  LogoutIcon,
  MenuIcon,
  XIcon
} from '@heroicons/react/solid';

const sections = [
  { path: '/', icon: HomeIcon, label: 'Inicio' },
  { path: '/Profile', icon: UserIcon, label: 'Perfil' },
  { path: '/TransactionHistory', icon: NewspaperIcon, label: 'Historial' },
  { path: '/PortfolioHome', icon: BriefcaseIcon, label: 'Portafolio' },
  { path: '/AffiliatesHome', icon: UsersIcon, label: 'Afiliados' },
  { path: '/TraderHome', icon: ChartBarIcon, label: 'Trader' },
  { path: '/CerrarSesion', icon: LogoutIcon, label: 'Cerrar Sesión' },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const handleSidebarClick = (path, label) => {
    navigate(path);
    setActiveSection(label);
    setSidebarOpen(false); // Close sidebar after click
  };

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isSidebarOpen && !event.target.closest('.sidebar-container') && !event.target.closest('.toggle-button')) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isSidebarOpen]);

  return (
    <div className="flex">
      <div className={`fixed top-0 left-0 h-full bg-gradient-to-r from-gray-800 to-gray-900 bg-opacity-90 text-teal-400 transition-transform duration-300 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:w-64 sidebar-container shadow-xl z-50`}>
        <div className="flex flex-col items-center md:items-start md:mt-8 p-4 w-full">
          <div className="text-xl font-semibold text-white mb-8">SVFINANCEX</div>
          {sections.map((section, index) => (
            <Link
              key={index}
              to={section.path}
              className={`flex items-center w-full px-4 py-2 mb-2 rounded-lg transition-all duration-300 hover:bg-gray-700 hover:text-white ${location.pathname === section.path ? 'bg-gray-700 text-white' : ''}`}
              onClick={() => handleSidebarClick(section.path, section.label)}
            >
              <section.icon className="w-6 h-6" />
              <span className="ml-3 text-xs md:text-base">{section.label}</span>
              {location.pathname === section.path && <div className="absolute inset-y-0 left-0 w-1 bg-teal-500 rounded-r-lg" />}
            </Link>
          ))}
        </div>
      </div>
      <div className={`flex-1 ml-0 md:ml-64 p-4 transition-all duration-300 ${isSidebarOpen ? 'opacity-50 pointer-events-none' : 'opacity-100 pointer-events-auto'}`}>
        {/* Aquí iría el contenido principal de la página */}
      </div>
      <button
        className="toggle-button md:hidden fixed top-4 left-4 p-2 bg-gray-800 rounded-full text-white hover:bg-gray-700 focus:outline-none z-50"
        onClick={handleToggleSidebar}
        aria-label={isSidebarOpen ? 'Close sidebar' : 'Open sidebar'}
      >
        {isSidebarOpen ? <XIcon className="w-6 h-6" /> : <MenuIcon className="w-6 h-6" />}
      </button>
    </div>
  );
};

export default Sidebar;
