import React from 'react'; 
import TradingForm from './TradingForm';

const TradingHome = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-900 text-white">
      <div className="lg:flex-1 p-8">
        <TradingForm />
      </div>
    </div>
  );
};

export default TradingHome;
