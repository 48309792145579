import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Sidebar from './Sidebar';
import { FaSync, FaArrowDown, FaArrowUp, FaSearch, FaRegEye } from 'react-icons/fa';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([
    { id: 1, type: 'Envío', amount: 500, date: '2024-01-05', status: true },
    { id: 2, type: 'Retiro', amount: 30.017, date: '2024-01-03', status: true },
        { id: 1, type: 'Transferencia', amount: 521, date: '2024-01-05', status: true },
    { id: 2, type: 'Retiro', amount: 2.29, date: '2024-01-03', status: true },
        { id: 1, type: 'Retiro', amount: 118.33, date: '2024-01-05', status: true },
    { id: 2, type: 'Retiro', amount: 841, date: '2024-01-03', status: false },
        { id: 1, type: 'Envío', amount: 1.755, date: '2024-01-05', status: false },
    // ... otras transacciones
  ]);

  const [error, setError] = useState(null);
  const transactionsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' para ascendente, 'desc' para descendente
  const [searchDate, setSearchDate] = useState('');

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleReload = () => {
    setTransactions([...transactions].reverse());
  };

  const handleSort = () => {
    const sortedTransactions = [...transactions].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.date.localeCompare(b.date);
      } else {
        return b.date.localeCompare(a.date);
      }
    });

    setTransactions(sortedTransactions);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearch = () => {
    const filteredTransactions = transactions.filter((t) =>
      t.date.includes(searchDate)
    );
    setTransactions(filteredTransactions);
  };

  const filteredTransactions =
    filterType === 'all' ? transactions : transactions.filter((t) => t.type === filterType);

  const offset = currentPage * transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(offset, offset + transactionsPerPage);

  const handleTransactionDetails = (id) => {
    // Lógica para mostrar detalles de la transacción, por ejemplo, con un modal
    const selectedTransaction = transactions.find((transaction) => transaction.id === id);
    console.log('Detalles de la transacción:', selectedTransaction);
    // Aquí podrías abrir un modal o realizar cualquier otra acción para mostrar detalles
  };

  return (
    <div className="flex h-screen bg-gray-900 text-white">
      <Sidebar />

      <div className="flex-1 p-8">
        <div className="bg-gray-900 text-white p-6 rounded-md">
          <h1 className="text-3xl font-bold mb-6">Historial de Transacciones</h1>

          {error && (
            <div className="mb-4 text-red-500">
              Hubo un error al cargar las transacciones. Por favor, intenta nuevamente.
            </div>
          )}

          <div className="mb-4 flex justify-between items-center">
            <label htmlFor="filter" className="text-sm text-gray-400 mr-2">
              Filtrar por tipo:
            </label>
            <select
              id="filter"
              name="filter"
              value={filterType}
              onChange={handleFilterChange}
              className="p-2 border rounded-md bg-gray-700 text-gray-300"
            >
              <option value="all">Todos</option>
              <option value="Envío">Envíos</option>
              <option value="Retiro">Retiros</option>
              {/* Agrega más opciones según tus tipos de transacciones */}
            </select>
            <button
              onClick={handleReload}
              className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:border-gray-700 focus:ring focus:ring-blue-200"
            >
              <FaSync className="mr-2" /> Recargar
            </button>
          </div>

          <div className="mb-4 flex items-center">
            <label htmlFor="search" className="text-sm text-gray-400 mr-2">
              Buscar por fecha:
            </label>
            <div className="relative">
              <input
                type="text"
                id="search"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                placeholder="YYYY-MM-DD"
                className="p-2 border rounded-md bg-gray-700 text-gray-300"
              />
              <button
                onClick={handleSearch}
                className="absolute right-0 top-0 bottom-0 px-3 bg-gray-700 text-gray-300 hover:bg-gray-600"
              >
                <FaSearch />
              </button>
            </div>
          </div>

          {currentTransactions.length === 0 ? (
            <p className="text-gray-400">No hay transacciones para mostrar.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border border-gray-700">
                <thead>
                  <tr className="bg-gray-800">
                    <th className="py-2 px-4 border-b cursor-pointer" onClick={handleSort}>
                      Fecha {sortOrder === 'asc' ? <FaArrowUp /> : <FaArrowDown />}
                    </th>
                    <th className="py-2 px-4 border-b">Tipo</th>
                    <th className="py-2 px-4 border-b">Monto</th>
                    <th className="py-2 px-4 border-b">Estado</th>
                    <th className="py-2 px-4 border-b">Detalles</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions.map((transaction, index) => (
                    <tr
                      key={transaction.id}
                      className={`${index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'} border-b border-gray-700`}
                    >
                      <td className="py-2 px-4">{transaction.date}</td>
                      <td className="py-2 px-4">{transaction.type}</td>
                      <td className="py-2 px-4">${transaction.amount}</td>
                      <td className="py-2 px-4">
                        {transaction.status ? (
                          <span className="flex items-center text-green-500">
                            <AiOutlineCheck className="mr-1" /> Aprobado
                          </span>
                        ) : (
                          <span className="flex items-center text-yellow-500">
                            <AiOutlineClose className="mr-1" /> Pendiente
                          </span>
                        )}
                      </td>
                      <td className="py-2 px-4 cursor-pointer text-blue-500 hover:text-blue-300" onClick={() => handleTransactionDetails(transaction.id)}>
                        <FaRegEye className="mr-1" /> Ver Detalles
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Eliminados los botones de siguiente y anterior */}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
