import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faTiktok, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-teal-300 py-12">
      <div className="container mx-auto px-4 text-center">
        <div className="mb-8">
          <p className="text-lg font-bold mb-4">&copy; {new Date().getFullYear()} SVFinancex.com</p>
          <p className="text-sm mb-6">Todos los derechos reservados | Desarrollado por NumblyStudios</p>
          <div className="flex flex-wrap justify-center space-y-2 md:space-x-6 md:space-y-0 mb-8">
            <FooterLink to="/PrivacyPolicy">Política de privacidad</FooterLink>
            <FooterLink to="/FAQ">Preguntas frecuentes</FooterLink>
            <FooterLink to="/Home">Contáctanos</FooterLink>
            <FooterLink to="/AfiliatesPage">Página de afiliados</FooterLink>
            <FooterLink to="/TermsAndConditions">Términos y condiciones</FooterLink>
          </div>
          <div className="flex items-center justify-center mb-6">
            <FontAwesomeIcon icon={faQuestionCircle} className="text-teal-300 mr-2" />
            <p className="text-xs">
              ¿Necesitas ayuda? Visita nuestra{' '}
              <Link to="/FAQ" className="text-teal-300 hover:text-pink-400">
                sección de ayuda
              </Link>
              .
            </p>
          </div>
          <p className="text-xs mb-4">Síguenos en nuestras redes sociales para mantenerte actualizado sobre nuestras novedades y promociones.</p>
          <div className="flex justify-center space-x-4 mb-8">
            <SocialLink href="https://www.tiktok.com/@svfinancex?lang=es" icon={faTiktok} text="TikTok" />
            <SocialLink href="https://x.com/svfinancex" icon={faTwitter} text="Twitter" />
            <SocialLink href="https://www.instagram.com/svfinancex/" icon={faInstagram} text="Instagram" />
          </div>
        </div>
        <div className="text-xs">
          <p>© 2024 Todos los derechos reservados</p>
        </div>
      </div>
    </footer>
  );
};

const SocialLink = ({ href, icon, text }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-teal-300 hover:text-pink-400 mx-2 transition duration-300 ease-in-out flex items-center"
    >
      <FontAwesomeIcon icon={icon} size="2x" />
      <span className="ml-2 hidden md:inline">{text}</span>
    </a>
  );
};

const FooterLink = ({ to, children }) => {
  return (
    <Link to={to} className="text-sm text-teal-300 hover:text-pink-400 transition duration-300 ease-in-out">
      {children}
    </Link>
  );
};

export default Footer;
