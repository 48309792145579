// Proyectos.js

import React from 'react';
import CryptoBadge from '../CryptoBadge';

const Proyectos = () => {
  const proyectos = [
    {
      name: 'Bitcoin Blanco',
      symbol: 'BTWE',
      completion: 100,
      color: 'blanco',
    },
    {
      name: 'Bitcoin Negro',
      symbol: 'BTBK',
      completion: 100,
      color: 'negro',
    },
    {
      name: 'Bitcoin Rojo',
      symbol: 'BTRD',
      completion: 100,
      color: 'rojo',
    },
    {
      name: 'Bitcoin Verde',
      symbol: 'BTGN',
      completion: 15,
      color: 'verde',
    },
    {
      name: 'Bitcoin Amarillo',
      symbol: 'BTYW',
      completion: 25,
      color: 'amarillo',
    },
    {
      name: 'Bitcoin Morado',
      symbol: 'BTPP',
      completion: 20,
      color: 'morado',
    },
    {
      name: 'Bitcoin Rosa',
      symbol: 'BTPK',
      completion: 10,
      color: 'rosa',
    },
    {
      name: 'Bitcoin Azul',
      symbol: 'BTBE',
      completion: 0,
      color: 'azul',
    },
  ];

  return (
    <div className="bg-gray-900 text-white py-16">
      <div className="container mx-auto mt-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-teal-500">Proyectos BRC20 en Unisat</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {proyectos.map((proyecto, index) => (
            <div key={index} className="rounded-lg overflow-hidden shadow-md bg-gray-900 transition-transform transform hover:scale-105 duration-300">
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <CryptoBadge color={proyecto.color} description={`Proyecto ${proyecto.name}`} />
                  <h2 className="text-xl font-semibold ml-2">{proyecto.name}</h2>
                </div>
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-500 bg-teal-200">
                      Completado: {proyecto.completion}%
                    </span>
                  </div>
                  <div className="flex">
                    <div className="flex flex-col items-center w-full">
                      <div className={`w-full h-2 rounded-full bg-gradient-to-r from-teal-200 to-teal-500`} style={{ width: `${proyecto.completion}%` }}></div>
                    </div>
                  </div>
                </div>
                <a
                  href={`https://unisat.io/brc20/${proyecto.symbol}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 bg-teal-500 hover:bg-teal-700 text-black font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline block mx-auto"
                >
                  Ver en Unisat
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Proyectos;
