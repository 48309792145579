import React from 'react';
import Sidebar from '../../Components/Sidebar';



const PortfolioHome = () => {
  return (
    <div className="flex h-screen bg-gray-900 text-white">
      <Sidebar /> {/* Agrega el Sidebar aquí */}

      <div className="flex-1 p-8">
        {/* Contenido específico de PortfolioHome */}
        <h1>Portfolio Home</h1>
        


        {/* Agrega más contenido según sea necesario */}
      </div>
    </div>
  );
};

export default PortfolioHome;
