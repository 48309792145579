import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TraderDetails = ({ details }) => (
  <div className="text-gray-800">
    <p className="mb-4">{details.bio}</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div>
        <h4 className="font-semibold mb-2">Activos</h4>
        <ul>
          {details.assets.map((asset, index) => (
            <li key={index}>{asset}</li>
          ))}
        </ul>
      </div>
      <div>
        <h4 className="font-semibold mb-2">Estrategias</h4>
        <ul>
          {details.strategies.map((strategy, index) => (
            <li key={index}>{strategy}</li>
          ))}
        </ul>
      </div>
    </div>
    <div className="mt-4">
      <h4 className="font-semibold mb-2">Rendimiento</h4>
      <p>Total Returns: {details.performance.totalReturns}%</p>
      <p>Win Rate: {details.performance.winRate}%</p>
      <p>Máxima Drawdown: {details.performance.maxDrawdown}%</p>
    </div>
  </div>
);

const TraderCard = ({ trader, isExpanded, onToggleDetails, onOpenDetails }) => (
  <div className="bg-gradient-to-r from-pink-400 to-teal-500 p-6 rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105">
    <h3
      className="text-xl font-semibold mb-2 text-gray-900 cursor-pointer"
      onClick={() => onOpenDetails(trader)}
    >
      {trader.name}
    </h3>
    {isExpanded ? (
      <TraderDetails details={trader.details} />
    ) : (
      <p className="text-gray-800 truncate">{trader.description}</p>
    )}
    <button
      onClick={() => onToggleDetails(trader.id)}
      className="mt-4 bg-teal-500 text-white px-4 py-2 rounded-md focus:outline-none"
    >
      {isExpanded ? 'Ocultar Detalles' : 'Ver Detalles'}
    </button>
    <Link to="/FormularioInversion">
      <button className="mt-2 bg-gray-800 text-white px-4 py-2 rounded-md focus:outline-none">
        Invertir
      </button>
    </Link>
  </div>
);

const BrokersPage = () => {
  const traders = [
    { 
      id: 1, 
      name: 'Monica', 
      description: 'Especializada en Criptomonedas.', 
      details: {
        bio: 'Soy un trader experimentado en el mercado de divisas (Crypto) con más de 5 años de experiencia.',
        assets: ['Criptomonedas', 'Acciones'],
        strategies: ['Análisis técnico', 'Gestión de riesgos'],
        performance: {
          totalReturns: 25.5,
          winRate: 75,
          maxDrawdown: 8.2,
        },
      },
    },
    { 
      id: 2, 
      name: 'Eloise', 
      description: 'Experto en acciones tecnológicas.', 
      details: {
        bio: 'Me especializo en acciones tecnológicas y busco oportunidades de inversión a largo plazo.',
        assets: ['Acciones', 'ETFs'],
        strategies: ['Análisis fundamental', 'Diversificación'],
        performance: {
          totalReturns: 18.2,
          winRate: 80,
          maxDrawdown: 5.5,
        },
      },
    },
    { 
      id: 3, 
      name: 'Smith', 
      description: 'Operador experimentado en criptomonedas.', 
      details: {
        bio: '(Traducido) Conozco el mercado de criptomonedas desde sus primeros días y he adaptado mi estrategia a la volatilidad del mercado.',
        assets: ['Bitcoin', 'Ethereum', 'Altcoins'],
        strategies: ['Swing trading', 'Análisis técnico'],
        performance: {
          totalReturns: 32.8,
          winRate: 70,
          maxDrawdown: 6.8,
        },
      },
    },
    { 
      id: 4, 
      name: 'Anah', 
      description: 'Trader en Acciones.', 
      details: {
        bio: '(Traducido) Enfoque en operaciones de futuros y estrategias de cobertura.',
        assets: ['Futuros', 'Opciones'],
        strategies: ['Hedging', 'Análisis técnico'],
        performance: {
          totalReturns: 22.5,
          winRate: 85,
          maxDrawdown: 7.2,
        },
      },
    },
    { 
      id: 5, 
      name: 'Jhon', 
      description: 'Inversionista a corto plazo en acciones.', 
      details: {
        bio: 'Busco oportunidades de inversión a corto plazo en el mercado de acciones.',
        assets: ['Acciones', 'ETFs'],
        strategies: ['Day trading', 'Análisis técnico'],
        performance: {
          totalReturns: 15.7,
          winRate: 75,
          maxDrawdown: 4.5,
        },
      },
    },
    { 
      id: 6, 
      name: 'Fede', 
      description: 'Estratega de inversiones en divisas.', 
      details: {
        bio: 'Desarrollo estrategias de inversión en el mercado de divisas para optimizar los retornos.',
        assets: ['Forex', 'Metales preciosos'],
        strategies: ['Algoritmos de trading', 'Gestión de cartera'],
        performance: {
          totalReturns: 28.3,
          winRate: 78,
          maxDrawdown: 6.5,
        },
      },
    },
    // Puedes agregar más traders según sea necesario
  ];

  const [expandedDetails, setExpandedDetails] = useState(null);

  const toggleDetails = (traderId) => {
    setExpandedDetails((prevDetails) => (prevDetails === traderId ? null : traderId));
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-900 min-h-screen p-8">
      <h2 className="text-3xl font-bold mb-6 text-white">Traders Certificados</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {traders.map((trader) => (
          <TraderCard
            key={trader.id}
            trader={trader}
            isExpanded={expandedDetails === trader.id}
            onToggleDetails={toggleDetails}
          />
        ))}
      </div>
    </div>
  );
};

export default BrokersPage;
