import React, { useReducer } from 'react';
import { motion } from 'framer-motion';

const initialState = {
  montoInversion: '',
  tipoInversion: 'traders',
  seguroCalculado: null,
  error: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MONTO_INVERSION':
      return { ...state, montoInversion: action.payload };
    case 'SET_TIPO_INVERSION':
      return { ...state, tipoInversion: action.payload };
    case 'CALCULAR_SEGURO':
      return { ...state, seguroCalculado: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}

const SegurosCapital = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const calcularSeguro = () => {
    const monto = parseFloat(state.montoInversion);
    if (isNaN(monto) || monto < 200) {
      dispatch({ type: 'SET_ERROR', payload: 'Por favor ingrese un monto de inversión válido (mínimo $200).' });
      return;
    }

    let porcentajeSeguro;
    switch (state.tipoInversion) {
      case 'traders':
        porcentajeSeguro = 0.2; // 50% de descuento en el seguro
        break;
      case 'bienesRaices':
        porcentajeSeguro = 0.2; // 50% de descuento en el seguro
        break;
      case 'poolsInversion':
        porcentajeSeguro = 0.175; // 50% de descuento en el seguro
        break;
      default:
        porcentajeSeguro = 0;
    }

    const seguro = monto * porcentajeSeguro;
    dispatch({ type: 'CALCULAR_SEGURO', payload: seguro.toFixed(2) });
    dispatch({ type: 'SET_ERROR', payload: '' });
  };

  const handleMontoInversionChange = (e) => {
    dispatch({ type: 'SET_MONTO_INVERSION', payload: e.target.value });
  };

  const handleTipoInversionChange = (e) => {
    dispatch({ type: 'SET_TIPO_INVERSION', payload: e.target.value });
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white font-sans">
      <div className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-8">Seguros de Capital e Información Sobre Comisiones</h2>
          <div className="mb-8">
            {/* Banner Promocional */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="bg-yellow-500 text-white py-4 px-6 mb-8 rounded-lg shadow-md"
            >
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <p className="text-lg font-semibold">
                    ¡Aprovecha nuestros seguros de capital!
                  </p>
                  <p className="text-sm mt-2">
                    Protege tu inversión con nosotros.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Información sobre Seguros de Capital */}
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="mb-12"
            >
              <h3 className="text-2xl font-semibold mb-4">Información sobre Seguros de Capital</h3>
              <p className="text-lg leading-7">
                Los seguros de capital son productos financieros que protegen su inversión en caso de pérdidas. En este tipo de seguro, se cubre el 50% de las pérdidas iniciales de su inversión.
              </p>
            </motion.section>
          </div>

          {/* Calculadora de Seguros */}
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            id="calcular-seguro"
            className="mb-12"
          >
            <div className="bg-gray-800 rounded-lg overflow-hidden p-6">
              <h3 className="text-2xl font-semibold text-white mb-4">Calculadora de Seguros</h3>
              <p className="text-lg leading-7 mb-4">
                Utilice la calculadora a continuación para estimar el costo del seguro en función de su inversión. El seguro se paga al hacer la inversión.
              </p>
              <div className="flex items-center mb-4">
                <label htmlFor="monto-inversion" className="mr-2 text-lg">Monto de Inversión ($):</label>
                <input
                  id="monto-inversion"
                  type="number"
                  value={state.montoInversion}
                  onChange={handleMontoInversionChange}
                  min="200"
                  className="w-32 p-3 border rounded text-black"
                  aria-label="Monto de Inversión"
                />
              </div>
              <div className="flex items-center mb-4">
                <label htmlFor="tipo-inversion" className="mr-2 text-lg">Tipo de Inversión:</label>
                <select
                  id="tipo-inversion"
                  value={state.tipoInversion}
                  onChange={handleTipoInversionChange}
                  className="w-48 p-3 border rounded text-black"
                >
                  <option value="traders">Intermediación de Traders</option>
                  <option value="bienesRaices">Bienes Raíces</option>
                  <option value="poolsInversion">Pools de Inversión</option>
                </select>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={calcularSeguro}
                className="bg-teal-600 text-white p-3 rounded hover:bg-teal-500 transition duration-300 ease-in-out mr-4"
              >
                Calcular Seguro
              </motion.button>
              {state.error && (
                <p className="mt-2 text-red-500">{state.error}</p>
              )}
              {state.seguroCalculado !== null && (
                <p className="mt-4 text-green-500">
                  El seguro estimado para una inversión de ${state.montoInversion} en {state.tipoInversion.replace(/([A-Z])/g, ' $1').toLowerCase()} es de ${state.seguroCalculado}.
                </p>
              )}
              <a
                href="https://commerce.coinbase.com/checkout/b1c22f9a-10f2-439f-bfce-53edc69a49d6"
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded inline-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pagar Seguro
              </a>
            </div>
          </motion.section>

          {/* Detalle del Seguro de Capital */}
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="mb-12"
          >
            <div className="bg-gray-800 rounded-lg overflow-hidden p-6">
              <h3 className="text-2xl font-semibold text-white mb-4">Detalle del Seguro de Capital</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h4 className="text-lg font-semibold mb-2">Lo que entra en el seguro:</h4>
                  <ul className="list-disc pl-6">
                    <li>Cobertura integral.</li>
                    <li>Reembolso de perdidas.</li>
                    <li>Flexibilidad.</li>
                    <li>Costo asequible.</li>
                    <li>Transparencia.</li>
                    <li>Facilidad en reclamaciones.</li>
                    <li>Respaldo monetario.</li>
                    <li>Actualización constante.</li>
                    <li>Asesoramiento profesional.</li>
                    <li>Sin límite de tiempo.</li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-lg font-semibold mb-2">Lo que no entra en el seguro:</h4>
                  <ul className="list-disc pl-6">
                    <li>Pérdidas posteriores.</li>
                    <li>Comisiones de los traders.</li>
                    <li>Riesgos no financieros.</li>
                    <li>Actividades de inversión de alto riesgo.</li>
                    <li>Fallos intencionales o negligencia.</li>
                    <li>Pérdidas por decisiones de inversión desfavorables.</li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.section>

          {/* Preguntas Frecuentes (FAQs) */}
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            className="mb-12"
          >
            <div className="bg-gray-800 rounded-lg overflow-hidden p-6">
              <h3 className="text-2xl font-semibold text-white mb-4">Preguntas Frecuentes (FAQs)</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="text-lg font-semibold">1. ¿Qué es un seguro de capital?</h4>
                  <p>Un seguro de capital es un producto financiero que protege tu inversión en caso de pérdidas.</p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">2. ¿Qué cubre el seguro de capital?</h4>
                  <p>El seguro de capital cubre el 50% de las pérdidas iniciales de tu inversión.</p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">3. ¿Cuánto cuesta el seguro de capital?</h4>
                  <p>El costo del seguro de capital depende del monto de tu inversión. Se calcula como el 10% del monto de inversión para intermediación de traders, 12.5% para bienes raíces, y 7.5% para pools de inversión.</p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">4. ¿Cómo puedo pagar el seguro de capital?</h4>
                  <p>Puedes pagar el seguro de capital al momento de realizar tu inversión utilizando el enlace proporcionado.</p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold">5. ¿Qué sucede si mi inversión tiene ganancias?</h4>
                  <p>Las ganancias no están cubiertas por el seguro. Solo se cubre el 50% de las pérdidas iniciales.</p>
                </div>
              </div>
            </div>
          </motion.section>
        </motion.div>
      </div>
    </div>
  );
};

export default SegurosCapital;
