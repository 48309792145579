// TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-900 text-white p-8">
      <p className="mb-4">
        Bienvenido a nuestra plataforma de intermediación de traders y usuarios. Al utilizar nuestros servicios,
        aceptas cumplir con los siguientes términos y condiciones.
      </p>
      <h2 className="text-lg font-semibold mb-2">1. Uso del Servicio</h2>
      <p className="mb-4">
        El servicio proporcionado está destinado únicamente para usuarios mayores de 18 años. El uso del servicio
        implica la aceptación de estos términos y condiciones.
      </p>
      <h2 className="text-lg font-semibold mb-2">2. Responsabilidades del Usuario</h2>
      <p className="mb-4">
        El usuario es responsable de mantener la confidencialidad de su cuenta y de cualquier actividad que ocurra bajo su cuenta.
      </p>
      <h2 className="text-lg font-semibold mb-2">3. Transacciones Financieras</h2>
      <p className="mb-4">
        Todas las transacciones financieras realizadas a través de la plataforma están sujetas a las leyes y regulaciones aplicables.
        La plataforma no se hace responsable de pérdidas financieras resultantes de transacciones.
      </p>
      {/* Más secciones */}
      <h2 className="text-lg font-semibold mb-2">4. Privacidad y Protección de Datos</h2>
      <p className="mb-4">
        La privacidad de nuestros usuarios es una prioridad. Consulta nuestra <a href="/privacy-policy" className="text-teal-300">Política de Privacidad</a> para obtener más información sobre cómo manejamos tus datos.
      </p>
      <h2 className="text-lg font-semibold mb-2">5. Uso del Sitio Web</h2>
      <p className="mb-4">
        Al utilizar nuestro sitio web, aceptas cumplir con nuestros <a href="/terms" className="text-teal-300">Términos y Condiciones</a>. Asegúrate de revisar nuestra <a href="/cookie-policy" className="text-teal-300">Política de Cookies</a> para obtener información sobre cómo utilizamos las cookies.
      </p>
      <h2 className="text-lg font-semibold mb-2">6. Modificaciones de los Términos</h2>
      <p className="mb-4">
        Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento.
        Se notificarán los cambios importantes a los usuarios.
      </p>
      <h2 className="text-lg font-semibold mb-2">7. Código de Conducta</h2>
      <p className="mb-4">
        Los usuarios deben seguir un código de conducta ético y respetuoso al interactuar en la plataforma.
      </p>
      <h2 className="text-lg font-semibold mb-2">8. Derechos de Autor</h2>
      <p className="mb-4">
        Todo el contenido publicado en la plataforma está protegido por derechos de autor. No se permite la reproducción no autorizada.
      </p>
      <h2 className="text-lg font-semibold mb-2">9. Limitaciones de Responsabilidad</h2>
      <p className="mb-4">
        La plataforma no se hace responsable de daños directos o indirectos resultantes del uso del servicio.
      </p>
      <h2 className="text-lg font-semibold mb-2">10. Terminación de Cuentas</h2>
      <p className="mb-4">
        La plataforma se reserva el derecho de terminar cuentas de usuarios que violen repetidamente los términos y condiciones.
      </p>
      <h2 className="text-lg font-semibold mb-2">11. Contacto con el Soporte</h2>
      <p className="mb-4">
        Para obtener asistencia o informar problemas, ponte en contacto con nuestro equipo de soporte a través de [correo electrónico o formulario de contacto].
      </p>
      <h2 className="text-lg font-semibold mb-2">12. Indemnización</h2>
      <p className="mb-4">
        Los usuarios aceptan indemnizar y eximir de responsabilidad a la plataforma y sus afiliados de cualquier reclamación o demanda, incluidos los honorarios legales.
      </p>
      <h2 className="text-lg font-semibold mb-2">13. Resolución de Disputas</h2>
      <p className="mb-4">
        Cualquier disputa será resuelta mediante negociación de buena fe. En caso de desacuerdo, las partes pueden recurrir a medios legales.
      </p>
      <h2 className="text-lg font-semibold mb-2">14. Ley Aplicable</h2>
      <p className="mb-4">
        Estos términos y condiciones se rigen por las leyes del [país/estado] y cualquier disputa está sujeta a la jurisdicción de los tribunales en [ciudad].
      </p>
      <h2 className="text-lg font-semibold mb-2">15. Cambios en el Servicio</h2>
      <p className="mb-4">
        La plataforma se reserva el derecho de modificar o descontinuar el servicio en cualquier momento sin previo aviso.
      </p>
      <h2 className="text-lg font-semibold mb-2">16. Aceptación y Vigencia de los Términos</h2>
      <p className="mb-4">
        Al utilizar nuestros servicios, aceptas estos términos y condiciones. Estos términos entran en vigencia a partir de [fecha].
      </p>
      <h2 className="text-lg font-semibold mb-2">17. Exención de Responsabilidad de Pérdidas Monetarias</h2>
      <p className="mb-4">
        SVFinancex no se hace responsable de las pérdidas monetarias sufridas por los usuarios como resultado del uso de nuestros servicios.
      </p>
      {/* Fin del contenido */}
      <p className="text-sm text-gray-500 mt-4">
        Estos términos y condiciones están sujetos a cambios sin previo aviso.
      </p>
    </div>
  );
};

export default TermsAndConditions;
