import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const RealEstateBanner = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Dólares pequeños animados */}
      <div className="absolute inset-0 z-0 pointer-events-none overflow-hidden">
        {[...Array(50)].map((_, index) => (
          <motion.div
            key={index}
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              scale: Math.random() * 0.5 + 0.5,
              opacity: 0,
            }}
            animate={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              opacity: 0.8,
              color: ['#38b2ac', '#805ad5'],
              transition: {
                duration: Math.random() * 5 + 2,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: 'easeInOut',
              },
            }}
            className="absolute text-transparent"
            style={{ fontSize: `${Math.random() * 24 + 10}px` }}
          >
            $
          </motion.div>
        ))}
      </div>

      {/* Contenido del banner */}
      <div className="container mx-auto px-4 text-center relative z-10 flex flex-col items-center justify-center min-h-screen">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8 uppercase tracking-wide break-words">
            <span className="bg-gradient-to-r from-teal-400 to-purple-400 text-transparent bg-clip-text animate-pulse">
              BIENES RAÍCES
            </span>
          </h1>
          <p className="text-base sm:text-lg md:text-xl mb-8 leading-relaxed">
            Invierte en propiedades de alto rendimiento con nuestra plataforma segura y transparente.
          </p>
          <div className="flex justify-center mb-12">
            <Link
              to="/InvestmentHome"
              className="bg-gray-800 hover:bg-teal-300 text-teal-300 font-bold py-3 px-6 rounded-full shadow-lg transition duration-300"
            >
              Descubre Más
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateBanner;
