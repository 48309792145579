// src/components/GananciasPerdidas.js
import React, { useState, useEffect } from 'react';
import { Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

const GananciasPerdidas = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const generateRandomData = () => {
      const newData = Array.from({ length: 10 }, (_, index) => ({
        id: index + 1,
        name: `Inversión ${index + 1}`,
        type: index % 2 === 0 ? 'Acciones' : 'Bonos',
        value: Math.floor(Math.random() * 1000) - 500, // Rango entre -500 y 500
        date: new Date(Date.now() - index * 24 * 60 * 60 * 1000),
      }));
      setData(newData);
    };

    const interval = setInterval(() => {
      generateRandomData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const getSquareColor = (value) => {
    return value >= 0 ? 'bg-green-500' : 'bg-red-500';
  };

  const getArrowIcon = (value) => {
    return value >= 0 ? <FiArrowUp className="text-white" /> : <FiArrowDown className="text-white" />;
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleRowClick = (id) => {
    // Lógica para manejar el clic en una fila (puedes agregar detalles adicionales o acciones)
    console.log(`Fila con ID ${id} clickeada`);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Detalles adicionales o acciones
    </Tooltip>
  );

  return (
    <div className="bg-gray-900 text-white p-6 rounded-md shadow-lg">
      <h2 className="text-3xl font-bold mb-4">Resumen de Ganancias y Pérdidas</h2>
      <Table responsive className="table-squares">
        <thead>
          <tr>
            <th>Inversión</th>
            <th>Tipo</th>
            <th>Monto</th>
            <th>Estado</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <OverlayTrigger
              key={item.id}
              placement="top"
              overlay={renderTooltip}
            >
              <tr key={item.id} onClick={() => handleRowClick(item.id)} className="cursor-pointer">
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td className={`square ${getSquareColor(item.value)}`}>
                  {getArrowIcon(item.value)}
                  <span className="text-white">{formatCurrency(Math.abs(item.value))}</span>
                </td>
                <td>
                  <Badge variant={getSquareColor(item.value)}>
                    {item.value >= 0 ? 'Ganancia' : 'Pérdida'}
                  </Badge>
                </td>
                <td>{formatDate(item.date)}</td>
                <td>
                  <span role="img" aria-label="Detalles" className="text-gray-400">
                    ℹ️
                  </span>
                </td>
              </tr>
            </OverlayTrigger>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GananciasPerdidas;
