// CryptoBadge.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin as iconVerde } from '@fortawesome/free-brands-svg-icons';

const colorClasses = {
  verde: 'bg-green-500',
  azul: 'bg-blue-500',
  rojo: 'bg-red-500',
  amarillo: 'bg-yellow-500',
  morado: 'bg-purple-500',
  blanco: 'bg-white text-black',
  negro: 'bg-black text-white',
  rosa: 'bg-pink-500',
  marron: 'bg-brown-500 text-white',
};

const iconMappings = {
  verde: iconVerde,
  azul: iconVerde,  // Cambiar a otro icono según tus necesidades
  rojo: iconVerde,  // Cambiar a otro icono según tus necesidades
  amarillo: iconVerde,  // Cambiar a otro icono según tus necesidades
  morado: iconVerde,  // Cambiar a otro icono según tus necesidades
  blanco: iconVerde,  // Cambiar a otro icono según tus necesidades
  negro: iconVerde,  // Cambiar a otro icono según tus necesidades
  rosa: iconVerde,  // Cambiar a otro icono según tus necesidades
  marron: iconVerde,  // Cambiar a otro icono según tus necesidades
};

const CryptoBadge = ({ color }) => {
  const badgeStyle = `inline-flex items-center p-2 rounded-full ${colorClasses[color]}`;

  return (
    <div className={badgeStyle}>
      <FontAwesomeIcon icon={iconMappings[color]} className="text-xl mr-0" />
    </div>
  );
};

export default CryptoBadge;
