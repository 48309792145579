import React from 'react';
import { Link } from 'react-router-dom';

const AffiliatesBanner = () => {
  return (
    <div className="bg-gray-900 text-white py-12 sm:py-16 lg:py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Contenido de texto */}
          <div className="md:w-1/2 md:pr-10 mb-8 md:mb-0">
            <h2 className="text-3xl lg:text-4xl font-semibold mb-4">¡Únete a nuestro programa de Afiliados!</h2>
            <p className="text-lg lg:text-xl mb-6">
              Conviértete en afiliado y comienza a ganar dinero recomendando nuestros productos a tus seguidores y amigos.
            </p>
            <div className="flex space-x-4">
              <Link
                to="/registrarse"
                className="bg-teal-500 hover:bg-teal-600 text-white py-2 px-6 rounded-lg font-semibold text-sm transition duration-300"
              >
                Registrarse como Afiliado
              </Link>
              <a
                href="#"
                className="border border-teal-500 hover:border-teal-600 text-teal-500 py-2 px-6 rounded-lg font-semibold text-sm transition duration-300"
              >
                Más información
              </a>
            </div>
          </div>
          {/* Imagen del banner */}
          <div className="md:w-1/2 relative overflow-hidden">
            <img
              src="https://via.placeholder.com/600x400.png?text=Banner+Image"
              alt="Banner"
              className="w-full h-auto object-cover rounded-lg shadow-lg transform transition-transform hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-900 opacity-50 hover:opacity-0 transition-opacity duration-300" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliatesBanner;
