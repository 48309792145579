import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaInfoCircle, FaTimes, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';  // Importa Helmet para la gestión de SEO



const pools = [
  {
    title: "ViralVentures Pool",
    description: "Se especializa en meme coins emergentes que están ganando tracción rápidamente. Identificamos y apoyamos las próximas grandes tendencias virales en criptomonedas.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-1"
  },
  {
    title: "MemeCulture Fund",
    description: "Invierte en meme coins vinculadas a fenómenos culturales actuales. Aprovecha el impacto de las tendencias culturales en el valor de tus inversiones.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-2"
  },
  {
    title: "EduMeme Ventures",
    description: "Combina la inversión en meme coins con formación educativa avanzada en criptomonedas. Aprende y crece en el mundo cripto mientras inviertes de manera inteligente.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-3"
  },
  {
    title: "CryptoPulse Diversify",
    description: "Ofrece una inversión equilibrada en una selección dinámica de meme coins. Minimiza riesgos y aprovecha el potencial de múltiples segmentos del mercado cripto.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-4"
  },
  {
    title: "EventHorizon Memes",
    description: "Invierte en meme coins relacionadas con eventos significativos y lanzamientos en el espacio cripto. Benefíciate de las oportunidades generadas por eventos clave.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-5"
  },
  {
    title: "MetaMeme Stability Fund",
    description: "Se enfoca en meme coins con mecanismos para gestionar la volatilidad y ofrecer estabilidad en el mercado. Ideal para quienes buscan un enfoque más equilibrado.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-6"
  },
  {
    title: "AgileMeme Strategies",
    description: "Administra inversiones en meme coins con ajustes continuos basados en análisis y tendencias del mercado. Maximiza el rendimiento con una gestión proactiva.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-7"
  },
  {
    title: "FusionMeme Portfolio",
    description: "Invierte en meme coins impulsadas por innovadoras colaboraciones y asociaciones estratégicas. Capitaliza el valor añadido de proyectos conjuntos y sinergias emergentes.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-8"
  },
  {
    title: "MemeCoin Titan Fund",
    description: "Dirigido a inversores VIP que buscan posicionarse en las meme coins con mayor capitalización de mercado. Accede a análisis detallados y oportunidades de inversión de gran escala.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-9"
  },
  {
    title: "DeFi Meme Masters",
    description: "Se enfoca en meme coins que están integradas en el ecosistema de finanzas descentralizadas (DeFi). Benefíciate de las innovaciones en DeFi invirtiendo en monedas de alta tendencia.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-10"
  },
  {
    title: "MemeXplorer Elite",
    description: "Ideal para inversores VIP que desean descubrir y apoyar las meme coins más innovadoras y disruptivas. Ofrece acceso exclusivo a monedas emergentes con alto potencial de transformación.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-11"
  },
  {
    title: "Sustainable Meme Fund",
    description: "Ofrece a los inversores VIP la oportunidad de invertir en meme coins con proyectos sostenibles y ecológicamente responsables. Se centra en monedas que promueven la sostenibilidad y el impacto social positivo.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-12"
  },
  {
    title: "MemeDAO Prestige",
    description: "Diseñado para inversores VIP interesados en meme coins gobernadas por organizaciones autónomas descentralizadas (DAO). Ofrece acceso a proyectos descentralizados, permitiendo influir en la toma de decisiones.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-13"
  },
  {
    title: "TechMeme Innovators Fund",
    description: "Se enfoca en meme coins asociadas a innovaciones tecnológicas y avances en el espacio cripto. Accede a monedas que están a la vanguardia de la tecnología blockchain, IA y otras áreas emergentes.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-14"
  },
  {
    title: "MemeArt Collective",
    description: "Combina el arte digital y los memes. Invierte en meme coins con un componente artístico significativo, incluyendo NFTs y proyectos de arte digital.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-15"
  },
  {
    title: "MemeGaming Legends Fund",
    description: "Dedicado a meme coins relacionadas con la industria del gaming. Ofrece acceso a monedas integradas en juegos, plataformas de eSports y proyectos de entretenimiento digital.",
    paymentLink: "https://commerce.coinbase.com/checkout/your-checkout-link-16"
  },
];

const getRandomInvestors = () => Math.floor(Math.random() * 10) + 1;

const MemecoinPoolsAd = () => {
  const [selectedPool, setSelectedPool] = useState(null);
  const [investors, setInvestors] = useState({});

  useEffect(() => {
    const updateInvestors = () => {
      const newInvestors = pools.reduce((acc, pool) => {
        acc[pool.title] = getRandomInvestors();
        return acc;
      }, {});
      setInvestors(newInvestors);
    };

    // Actualiza el número de inversores al cargar el componente
    updateInvestors();

    // Configura un intervalo para actualizar los inversores cada 24 horas
    const intervalId = setInterval(updateInvestors, 24 * 60 * 60 * 1000); // 24 horas en milisegundos

    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, []);

  return (
    <div className="bg-gradient-to-r from-gray-900 via-teal-500 to-gray-900 min-h-screen p-6 flex flex-col items-center">
      <Helmet>
        <title>Invierte en los Mejores Pools de Memecoins</title>
        <meta name="description" content="Descubre y invierte en los mejores pools de meme coins con nuestro catálogo especializado. Aprovecha las oportunidades de inversión en criptomonedas emergentes y tendencia." />
        <meta name="keywords" content="memecoins, inversiones en criptomonedas, pools de meme coins, tendencias criptográficas" />
        <meta property="og:title" content="Invierte en los Mejores Pools de Memecoins" />
        <meta property="og:description" content="Descubre y invierte en los mejores pools de meme coins con nuestro catálogo especializado. Aprovecha las oportunidades de inversión en criptomonedas emergentes y tendencia." />
        <meta property="og:image" content="URL_DE_IMAGEN" />
        <meta property="og:url" content="URL_DE_TU_SITIO" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <motion.h1
        className="text-5xl font-extrabold text-white mb-6 text-center drop-shadow-lg"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        ¡Invierte en los Mejores Pools de Memecoins!
      </motion.h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-6xl">
        {pools.map((pool, index) => (
          <motion.div
            key={index}
            className="bg-gray-900 rounded-lg p-6 text-center text-white transform hover:scale-105 transition duration-300"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.1 }}
          >
            <h2 className="text-2xl font-bold mb-2">{pool.title}</h2>
            <p className="text-gray-300 mb-4">{pool.description}</p>
            <div className="flex items-center justify-center mb-4">
              <FaUsers className="text-teal-500 mr-2" />
              <span className="text-gray-300">{investors[pool.title] || 0} Inversores</span>
            </div>
            <a
              href={pool.paymentLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-teal-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-teal-600 transition duration-300 flex items-center justify-center gap-2"
            >
              <FaInfoCircle />
              Más Detalles
            </a>
          </motion.div>
        ))}
      </div>

      <Transition appear show={!!selectedPool} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setSelectedPool(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold text-white"
                    >
                      {selectedPool?.title}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-200"
                      onClick={() => setSelectedPool(null)}
                      aria-label="Cerrar"
                    >
                      <FaTimes className="text-xl" />
                    </button>
                  </div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-300">
                      {selectedPool?.description}
                    </p>
                  </div>

                  <div className="mt-4">
                    <a
                      href={selectedPool?.paymentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex justify-center rounded-md border border-transparent bg-teal-500 px-4 py-2 text-sm font-medium text-white hover:bg-teal-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                    >
                      Comprar
                    </a>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 ml-4"
                      onClick={() => setSelectedPool(null)}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default MemecoinPoolsAd;