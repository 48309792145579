import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Vechain from '../Images/Vechain.png'; // Ajusta la ruta según la estructura de tu proyecto

const VetPage = () => {
  return (
    <div className="bg-gray-900 text-white">
      {/* Sección 1: Encabezado */}
      <header className="py-8 px-4 md:px-8 text-center">
        <div className="max-w-3xl mx-auto">
          <img src={Vechain} alt="VET Coin" className="w-48 md:w-64 mx-auto mb-6 rounded-full shadow-md" />
          <h1 className="text-3xl md:text-4xl font-bold mb-4">VeChain (VET) en nuestra plataforma</h1>
          <p className="text-lg md:text-xl">Descubre por qué VeChain es fundamental para el envío de monedas en SVFinancex.</p>
        </div>
      </header>

      {/* Sección 2: Introducción */}
      <section className="py-8 md:py-12 px-4 md:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Introducción a VeChain (VET)</h2>
          <p className="text-lg md:text-xl mb-6">
            VeChain (VET) es una plataforma blockchain que mejora la trazabilidad y transparencia en la gestión empresarial y la cadena de suministro.
          </p>
          <p className="text-lg md:text-xl mb-6">
            En SVFinancex, utilizamos VET debido a su eficiencia en la verificación y seguridad de transacciones, así como su capacidad para integrarse con sistemas empresariales complejos.
          </p>
        </div>
      </section>

      {/* Sección 3: Ventajas */}
      <section className="py-8 md:py-12 px-4 md:px-8 bg-gray-800">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Ventajas de utilizar VeChain (VET)</h2>
          <ul className="list-disc list-inside text-lg md:text-xl mb-6">
            <li>Seguridad avanzada con tecnología blockchain.</li>
            <li>Transacciones rápidas y de bajo costo.</li>
            <li>Capacidad de auditoría y trazabilidad mejorada.</li>
            <li>Integración fácil con sistemas existentes.</li>
            <li>Escalabilidad y capacidad para manejar grandes volúmenes de datos.</li>
          </ul>
        </div>
      </section>

      {/* Sección 4: Casos de Uso */}
      <section className="py-8 md:py-12 px-4 md:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Casos de Uso de VeChain (VET) en SVFinancex</h2>
          <p className="text-lg md:text-xl mb-6">
            VeChain (VET) se utiliza en nuestra plataforma para facilitar el envío seguro y eficiente de monedas entre usuarios y entidades asociadas.
          </p>
          <p className="text-lg md:text-xl mb-6">
            Desde transacciones diarias hasta pagos internacionales, VET ofrece una solución robusta para la transferencia de valor en nuestro ecosistema financiero.
          </p>
        </div>
      </section>

      {/* Sección 5: Funcionalidades Premium */}
      <section className="py-8 md:py-12 px-4 md:px-8 bg-gray-800">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Funcionalidades Premium de VeChain (VET) en SVFinancex</h2>
          <ul className="list-disc list-inside text-lg md:text-xl mb-6">
            <li>Acceso exclusivo a análisis de mercado en tiempo real.</li>
            <li>Oportunidades de inversión privilegiadas para poseedores de VET.</li>
            <li>Participación prioritaria en programas de recompensa y eventos especiales.</li>
            <li>Servicio al cliente dedicado y personalizado para usuarios de VET.</li>
            <li>Integración avanzada con sistemas de gestión financiera empresarial.</li>
            <li>Seguridad mejorada y protección contra amenazas cibernéticas.</li>
            <li>Actualizaciones continuas basadas en feedback y demandas de la comunidad.</li>
          </ul>
          <p className="text-lg md:text-xl mb-6">
            Para más información sobre cómo VeChain (VET) se integra y beneficia nuestra plataforma, visita la página oficial de VeChainThor.
          </p>
          <a
            href="https://www.vechain.org/"
            className="inline-flex items-center text-teal-400 hover:text-pink-300 transition duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visita VeChainThor
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default VetPage;
