// Home.js
import React from 'react';
import BannerPrincipal from './BannerPrincipal';
import CookieNotice from './CookieNoticie';
import TraderIntermediationBanner from './TraderIntermediationBanner';
import RealEstateBanner from './RealEstateBanner';
import InvestmentPoolsBanner from './InvestmentPoolsBanner';
import NFTMarketplaceBanner from './NFTMarketplaceBanner';
import TradingSimulationBanner from './TradingSimulationBanner';
import TopTraders from './TopTraders';
import VeChainCoin from '../Components/VechainCoin';
import BannerPromocional from './BannerPromocional';
import Chatbot from './ChatBot';
import TopInvestments from './TopInvestments';
import TotalEarningsCard from './TotalEarningsCard';
import DonationAddressCard from './DonationAddressCard';
import NewExchangeAnnouncement from './NewExchangeAnnouncement';


const Home = () => {
  return (
    <div>
      <NewExchangeAnnouncement />
      <Chatbot />
      <BannerPrincipal />
      <TraderIntermediationBanner />
      <RealEstateBanner />
      <InvestmentPoolsBanner />
      <NFTMarketplaceBanner />
      <TradingSimulationBanner />
      <TopTraders />
      <VeChainCoin />
      <TopInvestments />
      <TotalEarningsCard />
      <DonationAddressCard />
      <CookieNotice />
    </div>
  );
};

export default Home;
