import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { MailIcon } from '@heroicons/react/outline';

const EnhancedAffiliatePage = () => {
  const [email, setEmail] = useState('');
  const [registered, setRegistered] = useState(false);
  const [enlaces, setEnlaces] = useState([]);
  const [ganancias, setGanancias] = useState(0);
  const [afiliados, setAfiliados] = useState(0);
  const [conversiones, setConversiones] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulación de validación de correo electrónico
    if (email && email.includes('@')) {
      setTimeout(() => {
        setRegistered(true);
      }, 1000);
    } else {
      alert('Por favor, introduce un correo electrónico válido.');
    }
  };

  const generarEnlace = (productoId) => {
    if (registered) {
      const enlace = `https://tusitio.com/producto/${productoId}?afiliado=${email}`;
      setEnlaces([...enlaces, enlace]);
    } else {
      alert('Debes registrarte como afiliado antes de generar enlaces.');
    }
  };

  const registrarConversion = () => {
    const nuevaConversion = {
      producto: 'Producto X',
      comision: 50,
    };
    setConversiones([...conversiones, nuevaConversion]);
    setGanancias(ganancias + nuevaConversion.comision);
  };

  const totalConversiones = conversiones.length;

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      {/* Encabezado */}
      <header className="bg-teal-500 shadow-md py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-white">Panel de Afiliados</h1>
        </div>
      </header>

      {/* Contenido */}
      <main className="container mx-auto px-4 flex-grow py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Generador de Enlaces */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Generador de Enlaces de Afiliados</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex items-center">
                <input
                  type="email"
                  placeholder="Tu correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-3 rounded bg-gray-700 text-white"
                />
                <button
                  type="submit"
                  className="ml-2 bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm flex items-center"
                >
                  <MailIcon className="w-5 h-5 mr-2" />
                  Registrarse
                </button>
              </div>
            </form>
            <Transition
              show={registered}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {(ref) =>
                registered && (
                  <div ref={ref} className="mt-4">
                    <p className="text-teal-300">Gracias por registrarte como afiliado.</p>
                    <div className="mt-2">
                      <button onClick={() => generarEnlace(1)} className="bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm mr-2">
                        Enlace Producto 1
                      </button>
                      <button onClick={() => generarEnlace(2)} className="bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm">
                        Enlace Producto 2
                      </button>
                    </div>
                  </div>
                )
              }
            </Transition>
          </section>

          {/* Resumen de la Cuenta */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Resumen de la Cuenta</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="text-center">
                <p className="text-teal-300 text-lg font-bold">Dinero Ganado</p>
                <p className="text-3xl font-bold">{ganancias} USD</p>
              </div>
              <div className="text-center">
                <p className="text-teal-300 text-lg font-bold">Afiliados Conseguidos</p>
                <p className="text-3xl font-bold">{afiliados}</p>
              </div>
            </div>
          </section>

          {/* Historial de Enlaces */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Historial de Enlaces Generados</h2>
            <ul className="divide-y divide-gray-700">
              {enlaces.map((enlace, index) => (
                <li key={index} className="py-2">
                  <a href={enlace} className="text-teal-300 hover:underline" target="_blank" rel="noopener noreferrer">
                    Enlace {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </section>

          {/* Estadísticas de Conversión */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Estadísticas de Conversión</h2>
            <p className="text-teal-300 mb-2">Total de Conversiones: {totalConversiones}</p>
            <ul className="divide-y divide-gray-700">
              {conversiones.map((conversion, index) => (
                <li key={index} className="py-2">
                  <p className="text-white">
                    Producto: {conversion.producto} - Comisión: {conversion.comision} USD
                  </p>
                </li>
              ))}
            </ul>
            {registered && (
              <button onClick={registrarConversion} className="mt-4 bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm">
                Registrar Conversión
              </button>
            )}
          </section>

          {/* Promociones y Descuentos */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Promociones y Descuentos</h2>
            <p className="text-teal-300 mb-2">Aquí puedes encontrar las últimas promociones y descuentos para compartir con tus afiliados.</p>
            <div className="space-y-2">
              <div className="flex items-center">
                <p className="text-white">Código de Descuento:</p>
                <input type="text" className="ml-2 w-32 px-4 py-2 rounded bg-gray-700 text-white" placeholder="DESCUENTO25" />
              </div>
              <button className="bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm">Generar Enlace de Descuento</button>
            </div>
          </section>

          {/* Recursos y Capacitación */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Recursos y Capacitación</h2>
            <p className="text-teal-300 mb-2">Accede a recursos de marketing, guías de uso y materiales de capacitación para optimizar tus esfuerzos como afiliado.</p>
            <ul className="divide-y divide-gray-700">
              <li className="py-2">
                <a href="#" className="text-teal-300 hover:underline" target="_blank" rel="noopener noreferrer">
                  Guía de Marketing para Afiliados
                </a>
              </li>
              <li className="py-2">
                <a href="#" className="text-teal-300 hover:underline" target="_blank" rel="noopener noreferrer">
                  Webinars de Capacitación
                </a>
              </li>
              <li className="py-2">
                <a href="#" className="text-teal-300 hover:underline" target="_blank" rel="noopener noreferrer">
                  Tutoriales en Video
                </a>
              </li>
            </ul>
          </section>

          {/* Soporte y Ayuda */}
          <section className="bg-gray-800 p-6 rounded shadow-md">
            <h2 className="text-xl font-semibold mb-4">Soporte y Ayuda</h2>
            <p className="text-teal-300 mb-2">¿Necesitas ayuda? Contacta con nuestro equipo de soporte para resolver cualquier duda o problema que tengas.</p>
            <button className="bg-teal-300 hover:bg-teal-400 py-2 px-4 rounded font-semibold uppercase text-sm">Contactar Soporte</button>
          </section>
        </div>
      </main>
    </div>
  );
};

export default EnhancedAffiliatePage;
