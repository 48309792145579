import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = () => {
    setAnchorEl(anchorEl ? null : document.body);
  };

  const menuItems = [
    { to: '/Home', label: 'Inicio' },
    { to: '/BrokersPage', label: 'Traders' },
    { to: '/InvestmentPoolsPage', label: 'Pools de inversión' },
    { to: '/InvestmentHome', label: 'Bienes Raíces' },
    { to: '/Nosotros', label: 'Nosotros' },
    { to: '/TraderCertification', label: 'Únete' },
    { to: '/SegurosCapital', label: 'Seguros de Capital' },
    { to: '/FAQ', label: 'FAQ' }
  ];

  const HeaderLogo = () => (
    <Link to="/" className="flex items-center" aria-label="Ir a la página principal">
      <Typography variant="h5" className="text-white">
        SVFinancex
      </Typography>
    </Link>
  );

  const Navigation = () => (
    <div>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} className="text-white text-2xl cursor-pointer" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        PaperProps={{ style: { backgroundColor: '#1a202c' } }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={handleClose}>
            <Link
              to={item.to}
              className="block text-teal-300 hover:text-pink-300 py-2 px-4 transition duration-300"
              onClick={handleClose}
            >
              {item.label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    <>
      {/* Header for desktop */}
      {!isMobile && (
        <header className="bg-gray-900 p-4 flex justify-between items-center">
          <HeaderLogo />
          <div className="flex items-center space-x-4">
            {menuItems.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className="text-white hover:text-teal-300 transition duration-300 ease-in-out transform hover:scale-105"
                aria-label={`Ir a ${item.label}`}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </header>
      )}

      {/* Menu for mobile */}
      {isMobile && (
        <div className="bg-gray-900 p-4">
          <Navigation />
        </div>
      )}
    </>
  );
};

export default Header;
