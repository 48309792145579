// PolicyPage.js
import React from 'react';

const PolicyPage = () => {
  return (
    <div className="container mx-auto mt-8 text-white bg-gray-900 p-8 rounded-md">
      <h1 className="text-4xl font-bold mb-4">Política de Cookies</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">1. Introducción</h2>
        <p>
          Bienvenido a nuestra política de cookies. Esta página explica cómo utilizamos las cookies en nuestro sitio web. Al utilizar nuestro sitio, aceptas el uso de cookies de acuerdo con esta política.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">2. ¿Qué son las cookies?</h2>
        <p>
          Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web. Se utilizan para mejorar tu experiencia de navegación al recordar tus preferencias y proporcionar información sobre cómo interactúas con el sitio.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">3. Tipos de Cookies que Utilizamos</h2>
        <p>
          Utilizamos varios tipos de cookies, incluyendo:
        </p>
        <ul className="list-disc pl-6">
          <li>Cookies esenciales: necesarias para el funcionamiento básico del sitio.</li>
          <li>Cookies de rendimiento: nos ayudan a mejorar el rendimiento del sitio recopilando información sobre cómo se utiliza.</li>
          <li>Cookies de funcionalidad: mejoran la funcionalidad del sitio recordando tus preferencias.</li>
          <li>Cookies de publicidad: utilizadas para proporcionar anuncios más relevantes para ti.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">4. Consentimiento</h2>
        <p>
          Al visitar nuestro sitio, se te mostrará un banner de cookies que te informará sobre el uso de cookies y te pedirá tu consentimiento. Al hacer clic en "Aceptar Cookies" estás aceptando nuestra política de cookies. Puedes gestionar tus preferencias de cookies a través de la configuración de tu navegador o ajustarlas en nuestro banner de cookies en cualquier momento.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">5. Configuración de Cookies</h2>
        <p>
          Puedes gestionar tus preferencias de cookies a través de la configuración de tu navegador. También proporcionamos opciones en nuestro banner de cookies para que ajustes tus preferencias en cualquier momento. Ten en cuenta que deshabilitar ciertas cookies puede afectar la funcionalidad de nuestro sitio web.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">6. Cambios en la Política</h2>
        <p>
          Nos reservamos el derecho de realizar cambios en nuestra política de cookies. Cualquier cambio significativo será notificado a través de nuestro sitio web o por otros medios apropiados. Al continuar utilizando nuestro sitio después de dichos cambios, aceptas las actualizaciones de la política de cookies. Te recomendamos revisar periódicamente nuestra política para estar informado sobre cualquier cambio.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-2">7. Contacto</h2>
        <p>
          Si tienes alguna pregunta o inquietud sobre nuestra política de cookies, no dudes en contactarnos a través de [correo electrónico o formulario de contacto].
        </p>
      </section>

      {/* Puedes agregar más secciones según las necesidades específicas del país en el que operas */}
    </div>
  );
};

export default PolicyPage;
