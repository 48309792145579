import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FormularioInversion() {
  const [brokerElegido, setBrokerElegido] = useState('');
  const [montoInversion, setMontoInversion] = useState(200);
  const [plazoInversion, setPlazoInversion] = useState(5); // Cambiado a 5 días
  const [seguroCapital, setSeguroCapital] = useState(false);
  const [riesgoInversion, setRiesgoInversion] = useState(50);
  const [cargando, setCargando] = useState(false);

  const handleMontoInversionChange = (e) => {
    setMontoInversion(parseInt(e.target.value, 10));
  };

  const handlePlazoInversionChange = (e) => {
    setPlazoInversion(parseInt(e.target.value, 10));
  };

  const handleSeguroCapitalChange = (e) => {
    setSeguroCapital(e.target.checked);
  };

  const handleRiesgoInversionChange = (e) => {
    setRiesgoInversion(parseInt(e.target.value, 10));
  };

  const handleBrokerElegidoChange = (e) => {
    setBrokerElegido(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCargando(true);

    setTimeout(() => {
      setCargando(false);
      const mensajeWhatsApp = `
        Hola, estoy interesado en realizar una inversión con las siguientes especificaciones:

        💰 Monto de inversión: $${montoInversion}
        ⏳ Plazo de inversión: ${plazoInversion} días
        🛡️ Seguro de capital: ${seguroCapital ? 'Sí' : 'No'}
        📉 Nivel de riesgo: ${riesgoInversion}%
        🤝 Broker Elegido: ${brokerElegido}

        ¡Espero tu pronta respuesta!
      `;

      const numeroWhatsApp = '621351623';
      const enlaceWhatsApp = `https://wa.me/${numeroWhatsApp}/?text=${encodeURIComponent(mensajeWhatsApp)}`;
      window.location.href = enlaceWhatsApp;

      // Mostrar notificación de éxito
      toast.success('¡Tu solicitud ha sido enviada con éxito!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 3000);
  };

  const getRiesgoTipo = () => {
    if (riesgoInversion < 33) {
      return 'Bajo';
    } else if (riesgoInversion >= 33 && riesgoInversion < 66) {
      return 'Moderado';
    } else {
      return 'Alto';
    }
  };

  // Efecto para el fondo con movimiento
  useEffect(() => {
    const handleMouseMove = (e) => {
      document.documentElement.style.setProperty('--mouseX', e.clientX / window.innerWidth - 0.5);
      document.documentElement.style.setProperty('--mouseY', e.clientY / window.innerHeight - 0.5);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-teal-600 to-pink-600 bg-opacity-75">
      <div className="max-w-md w-full p-6 bg-gray-900 text-teal-500 rounded-lg shadow-xl relative">
        <h2 className="text-3xl font-semibold mb-4">Invierte Sabiendo los riesgos</h2>
        <select
          id="brokerElegido"
          value={brokerElegido}
          onChange={handleBrokerElegidoChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="">Selecciona un Broker</option>
          <option value="MónicaSVFX">MónicaSVFX</option>
          <option value="SmithSVFX">SmithSVFX</option>
          <option value="AnahSVFX">AnahSVFX</option>
          <option value="JhonSVFX">JhonSVFX</option>
          <option value="FedeSVFX">FedeSVFX</option>
          <option value="EloiseSVFX">EloiseSVFX</option>
          {/* Agregar más opciones según sea necesario */}
        </select>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label htmlFor="montoInversion" className="block mb-2">Monto de Inversión ($):</label>
            <input
              type="number"
              id="montoInversion"
              value={montoInversion}
              onChange={handleMontoInversionChange}
              className="w-full p-2 border rounded"
              min="200"
              max="100000"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="plazoInversion" className="block mb-2">Plazo de Inversión (días):</label>
            <input
              type="range"
              id="plazoInversion"
              value={plazoInversion}
              onChange={handlePlazoInversionChange}
              min="5"
              max="365"
              step="1"
              className="w-full bg-gray-800"
            />
            <p className="text-center mt-2 text-teal-500">Duración: {plazoInversion} día(s)</p>
          </div>
          <div className="mb-4">
            <label htmlFor="riesgoInversion" className="block mb-2">Nivel de Riesgo:</label>
            <input
              type="range"
              id="riesgoInversion"
              value={riesgoInversion}
              onChange={handleRiesgoInversionChange}
              min="0"
              max="100"
              step="1"
              className="w-full bg-gray-800"
            />
            <p className="text-center mt-2 text-teal-500">Riesgo: {getRiesgoTipo()}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="seguroCapital" className="block mb-2">Seguro de Capital:</label>
            <input
              type="checkbox"
              checked={seguroCapital}
              onChange={handleSeguroCapitalChange}
              className="text-teal-500"
            />
          </div>
          <div className="mb-4">
            {/* Coinbase Commerce Checkout Button */}
            <div>
              <a
                className="donate-with-crypto bg-gray-500 text-white p-2 rounded hover:bg-teal-600 w-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-teal-500"
                href="https://commerce.coinbase.com/checkout/c49d2795-9291-4178-b9f9-7c01060fb19f"
              >
                Pagar con Coinbase
              </a>
              <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807"></script>
            </div>
          </div>
          <button
            type="submit"
            className="bg-teal-500 text-white p-2 rounded hover:bg-teal-600 w-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-teal-500"
          >
            Contactar
          </button>
        </form>
        {cargando && <p className="mt-4">Cargando....</p>}
        <ToastContainer />
      </div>
    </div>
  );
}

export default FormularioInversion;
