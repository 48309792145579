import React, { useState, useEffect, Fragment } from 'react';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { FaInfoCircle, FaTimes, FaUsers, FaDollarSign, FaCalendarAlt, FaPiggyBank } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const getRandomInvestors = () => Math.floor(Math.random() * 10) + 1;

const pools = [
  {
    id: 1,
    title: "Pool de Inversión en Cripto Tradicional",
    description: "Invierte en criptomonedas consolidadas y prometedoras. Incluye BTC, ETH y otras criptos con alta capitalización y estabilidad.",
    expectedReturn: '10% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: '1 año',
    liquidity: 'Alta',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    id: 2,
    title: "Pool de Inversión en Nuevas Criptomonedas",
    description: "Accede a oportunidades en criptomonedas emergentes con alto potencial de crecimiento. Perfecto para inversores que buscan nuevas oportunidades.",
    expectedReturn: '20% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: '6 meses',
    liquidity: 'Media',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    id: 3,
    title: "Pool de Cripto Minería",
    description: "Invierte en equipos de minería de criptomonedas y comparte los beneficios obtenidos. Ideal para aquellos interesados en la minería digital.",
    expectedReturn: '15% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: '1 año',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    id: 4,
    title: "Pool de Stablecoins",
    description: "Inversión en monedas estables como USDT y USDC. Ofrece estabilidad y seguridad con rendimientos bajos pero confiables.",
    expectedReturn: '5% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Alta',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    id: 5,
    title: "Pool de DeFi",
    description: "Invierte en proyectos de Finanzas Descentralizadas. Accede a oportunidades en préstamos, staking y yield farming en el ecosistema DeFi.",
    expectedReturn: '12% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: '6 meses',
    liquidity: 'Media',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  }
];

const memecoinPools = [
  {
    title: "MemeCoin Growth Fund",
    description: "Enfocado en meme coins emergentes con alto potencial de crecimiento. Ideal para inversores interesados en monedas virales y de tendencia.",
    expectedReturn: '10% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeCoin Stable Value Pool",
    description: "Invierte en meme coins que han demostrado estabilidad en el mercado. Ideal para aquellos que buscan un equilibrio entre riesgo y retorno.",
    expectedReturn: '7% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Media',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeCoin Trendsetter Fund",
    description: "Apunta a meme coins que están en tendencia y ganando popularidad rápidamente. Aprovecha las oportunidades antes de que se vuelvan mainstream.",
    expectedReturn: '15% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeCoin Community Pool",
    description: "Se centra en meme coins con una comunidad activa y comprometida. Ideal para aquellos que creen en el poder de las comunidades en el espacio cripto.",
    expectedReturn: '8% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Media',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeCoin Tech Innovators",
    description: "Invierte en meme coins asociadas a innovaciones tecnológicas y avances en el espacio cripto. Accede a monedas que están a la vanguardia de la tecnología blockchain, IA y otras áreas emergentes.",
    expectedReturn: '5% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeArt Collective",
    description: "Combina el arte digital y los memes. Invierte en meme coins con un componente artístico significativo, incluyendo NFTs y proyectos de arte digital.",
    expectedReturn: '5% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
  {
    title: "MemeGaming Legends Fund",
    description: "Dedicado a meme coins relacionadas con la industria del gaming. Ofrece acceso a monedas integradas en juegos, plataformas de eSports y proyectos de entretenimiento digital.",
    expectedReturn: '5% anual',
    usersCount: getRandomInvestors(),
    minimumCapital: '$10',
    duration: 'Indefinido',
    liquidity: 'Baja',
    availability: 'Disponible',
    paymentLink: "https://commerce.coinbase.com/checkout/da76b357-6968-4ea2-848a-1ac5cf4a4fc9"
  },
];

const getRandomIncrement = () => Math.floor(Math.random() * 10) + 1;

const InvestmentPoolsPage = () => {
  const [selectedPool, setSelectedPool] = useState(null);
  const [investors, setInvestors] = useState({});

  const initializeInvestors = () => {
    const savedInvestors = JSON.parse(localStorage.getItem('investors')) || {};
    let needsUpdate = false;

    [...pools, ...memecoinPools].forEach(pool => {
      if (savedInvestors[pool.title] === undefined) {
        savedInvestors[pool.title] = pool.usersCount;
        savedInvestors[`${pool.title}_date`] = new Date().toDateString();
        needsUpdate = true;
      }
    });

    if (needsUpdate) {
      localStorage.setItem('investors', JSON.stringify(savedInvestors));
    }

    setInvestors(savedInvestors);
  };

  const updateInvestors = () => {
    const currentDate = new Date().toDateString();
    const savedInvestors = JSON.parse(localStorage.getItem('investors')) || {};

    const updatedInvestors = {};
    [...pools, ...memecoinPools].forEach(pool => {
      const lastUpdateDate = savedInvestors[`${pool.title}_date`] || currentDate;
      if (lastUpdateDate !== currentDate) {
        const newCount = (savedInvestors[pool.title] || 0) + getRandomIncrement();
        savedInvestors[pool.title] = newCount;
        savedInvestors[`${pool.title}_date`] = currentDate;
      }
      updatedInvestors[pool.title] = savedInvestors[pool.title];
    });

    setInvestors(updatedInvestors);
    localStorage.setItem('investors', JSON.stringify(savedInvestors));
  };

  useEffect(() => {
    initializeInvestors();
    updateInvestors(); // Llamada inicial para actualizar el conteo

    const intervalId = setInterval(updateInvestors, 24 * 60 * 60 * 1000); // Actualiza cada 24 horas

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen p-6 flex flex-col items-center">
      <Helmet>
        <title>Invierte en los Mejores Pools de Inversión</title>
        <meta
          name="description"
          content="Descubre y invierte en los mejores pools de inversión con nuestro catálogo especializado. Aprovecha las oportunidades de inversión en diversos mercados y criptomonedas emergentes."
        />
        <meta
          name="keywords"
          content="inversiones, criptomonedas, pools de inversión, tendencias de mercado"
        />
      </Helmet>

      <div className="max-w-4xl w-full">
        <h1 className="text-4xl font-bold text-white mb-8 text-center">Explora Nuestros Pools de Inversión</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[...pools, ...memecoinPools].map((pool) => (
            <motion.div
              key={pool.title}
              className="bg-gray-800 text-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105"
              whileHover={{ scale: 1.05 }}
            >
              <h2 className="text-2xl font-bold mb-4">{pool.title}</h2>
              <p className="text-gray-400 mb-4">{pool.description}</p>
              <div className="flex justify-between items-center">
                <button
                  className="bg-teal-300 text-gray-900 py-2 px-4 rounded-lg hover:bg-teal-400"
                  onClick={() => window.open(pool.paymentLink, '_blank')}
                >
                  Invertir
                </button>
              </div>
              <button
                className="mt-4 text-teal-300 hover:underline flex items-center"
                onClick={() => setSelectedPool(pool)}
              >
                <FaInfoCircle className="mr-2" /> Más Información
              </button>
            </motion.div>
          ))}
        </div>
      </div>

      <Transition show={Boolean(selectedPool)} as={Fragment}>
        <Dialog as="div" open={Boolean(selectedPool)} onClose={() => setSelectedPool(null)}>
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="max-w-md mx-auto bg-gray-800 text-white p-6 rounded-lg shadow-lg">
              {selectedPool ? (
                <>
                  <div className="flex justify-between items-center">
                    <Dialog.Title className="text-2xl font-bold">{selectedPool.title}</Dialog.Title>
                    <button onClick={() => setSelectedPool(null)}>
                      <FaTimes className="text-teal-300" />
                    </button>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-400 mb-2"><strong>Descripción:</strong> {selectedPool.description}</p>
                    <p className="text-gray-400 mb-2 flex items-center"><FaDollarSign className="mr-2" /> <strong>Retorno Esperado:</strong> {selectedPool.expectedReturn}</p>
                    <p className="text-gray-400 mb-2 flex items-center"><FaUsers className="mr-2" /> <strong>Usuarios Invertidos:</strong> {investors[selectedPool.title] || 0}</p>
                    <p className="text-gray-400 mb-2 flex items-center"><FaPiggyBank className="mr-2" /> <strong>Capital Mínimo:</strong> {selectedPool.minimumCapital}</p>
                    <p className="text-gray-400 mb-2 flex items-center"><FaCalendarAlt className="mr-2" /> <strong>Duración:</strong> {selectedPool.duration}</p>
                    <p className="text-gray-400 mb-2"><strong>Liquidez:</strong> {selectedPool.liquidity}</p>
                    <p className="text-gray-400 mb-2"><strong>Disponibilidad:</strong> {selectedPool.availability}</p>
                  </div>
                  <button
                    className="bg-teal-300 text-gray-900 py-2 px-4 rounded-lg hover:bg-teal-400 mt-4"
                    onClick={() => window.open(selectedPool.paymentLink, '_blank')}
                  >
                    Invertir
                  </button>
                </>
              ) : null}
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default InvestmentPoolsPage;
