import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion'; // Para animaciones

// Datos de inversión simulados para demostración
const fetchInvestmentData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve([
        { 
          id: 1, 
          category: 'Intermediarios de Traders', 
          data: [
            { id: 1, name: 'Fede', performance: '115%' },
            { id: 2, name: 'Anah', performance: '24%' },
            { id: 3, name: 'Jhon', performance: '17%' },
          ],
        },
        { 
          id: 2, 
          category: 'Pools de Inversión', 
          data: [
            { id: 1, name: 'MemeGaming Legends Fund', roi: '58%' },
            { id: 2, name: 'Pool de DeFi', roi: '38%' },
            { id: 3, name: 'Pool de Inversión en Nuevas Criptomonedas', roi: '25%' },
          ],
        },
        { 
          id: 3, 
          category: 'Bienes Raíces', 
          data: [
            { id: 1, name: 'Chalet pareado en venta en Canillas', roi: '2.3%' },
            { id: 2, name: 'Chalet Independiente', roi: '1.8%' },
            { id: 3, name: 'Piso Moderno en el Centro de la Ciudad', roi: '1.3%' },
          ],
        },
      ]);
    }, 1000)
  );

// Componente de tabla de inversión
const InvestmentTable = memo(({ title, data, columns }) => (
  <motion.div 
    className="mb-8 p-4 bg-gray-800 rounded-lg shadow-lg"
    initial={{ opacity: 0 }} 
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="text-xl font-bold text-teal-300 mb-4">{title}</h3>
    <table className="min-w-full bg-gray-900 text-white rounded-lg overflow-hidden">
      <thead>
        <tr>
          <th className="py-3 px-4 bg-gray-800 text-left text-xs font-medium text-teal-300 uppercase tracking-wider" aria-label="Rank">Rank</th>
          {columns.map((column) => (
            <th key={column.key} className="py-3 px-4 bg-gray-800 text-left text-xs font-medium text-teal-300 uppercase tracking-wider" aria-label={column.label}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <motion.tr 
            key={item.id} 
            className={`${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}`}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <td className="py-2 px-4 border-b border-gray-600">{index + 1}</td>
            {columns.map((column) => (
              <td key={column.key} className="py-2 px-4 border-b border-gray-600">
                {item[column.key]}
              </td>
            ))}
          </motion.tr>
        ))}
      </tbody>
    </table>
  </motion.div>
));

InvestmentTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

// Componente principal de inversiones
const TopInvestments = () => {
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredInvestments, setFilteredInvestments] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchInvestmentData();
        setInvestments(data);
        setFilteredInvestments(data);
      } catch (err) {
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setFilteredInvestments(investments.map(category => ({
        ...category,
        data: category.data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())),
      })));
    } else {
      setFilteredInvestments(investments);
    }
  }, [searchTerm, investments]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchInvestmentData().then(data => {
      setInvestments(data);
      setFilteredInvestments(data);
      setLoading(false);
    }).catch(() => {
      setError('Failed to load data');
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-8 text-center">
        <div className="text-xl mb-4">Cargando...</div>
        <div className="animate-spin border-4 border-t-4 border-teal-300 w-12 h-12 rounded-full mx-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-8 text-center">
        <div className="text-xl mb-4">Error: {error}</div>
        <button 
          onClick={handleRefresh}
          className="mt-4 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 transition"
        >
          Reintentar
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-center text-teal-300 mb-8">Top Inversiones</h2>
        
        <div className="mb-8 flex justify-between items-center">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Buscar por nombre..."
            className="px-4 py-2 bg-gray-800 border border-gray-700 rounded w-full md:w-1/2"
          />
          <button 
            onClick={handleRefresh}
            className="ml-4 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700 transition"
          >
            Refrescar
          </button>
        </div>

        {filteredInvestments.map((investment) => (
          <InvestmentTable
            key={investment.id}
            title={investment.category}
            data={investment.data}
            columns={
              investment.category === 'Intermediarios de Traders'
                ? [{ key: 'name', label: 'Trader' }, { key: 'performance', label: 'Performance (%)' }]
                : [{ key: 'name', label: 'Name' }, { key: 'roi', label: 'ROI (%)' }]
            }
          />
        ))}
      </div>
    </div>
  );
};

export default TopInvestments;
