import React from 'react';
import { motion } from 'framer-motion';

const NFTMarketplaceBanner = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Dólares pequeños animados */}
      <div className="absolute inset-0 z-0 pointer-events-none overflow-hidden">
        {[...Array(50)].map((_, index) => (
          <motion.div
            key={index}
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              scale: Math.random() * 0.5 + 0.5,
              opacity: 0,
            }}
            animate={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              opacity: 0.8,
              color: ['#38b2ac', '#805ad5'],
              transition: {
                duration: Math.random() * 5 + 2,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: 'easeInOut',
              },
            }}
            className="absolute text-transparent"
            style={{ fontSize: `${Math.random() * 24 + 10}px` }}
          >
            $
          </motion.div>
        ))}
      </div>

      {/* Contenido del banner */}
      <div className="container mx-auto px-4 text-center relative z-10 flex flex-col items-center justify-center min-h-screen">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8 uppercase tracking-wide break-words">
            <span className="bg-gradient-to-r from-teal-400 to-purple-400 text-transparent bg-clip-text animate-pulse">
              MARKETPLACE DE NFTS
            </span>
          </h1>
          <p className="text-base sm:text-lg md:text-xl mb-8 leading-relaxed">
            Explora, compra y vende NFTs únicos en nuestro marketplace de vanguardia, Disponible en diciembre.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NFTMarketplaceBanner;
