import React from 'react';
import Sidebar from '../../Components/Sidebar';
import AffiliatePage from './AffiliatePage';
import AffiliatesBanner from './AffiliatesBanner';

const AffiliatesHome = () => {
  return (
    <div>
      <Sidebar />
      <div className="ml-0 md:ml-64"> {/* Ajuste de margen izquierdo */}
        <AffiliatesBanner />
        <AffiliatePage />
      </div>
    </div>
  );
};

export default AffiliatesHome;
