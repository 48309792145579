import React, { useState, useRef, useEffect } from 'react';
import { FaLeaf, FaRecycle, FaTree } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import html2canvas from 'html2canvas';

const RetoAleatorio = () => {
  const [retoActual, setRetoActual] = useState(null);
  const [participantes, setParticipantes] = useState(0);
  const [diasRestantes, setDiasRestantes] = useState(10); // Modifica según la duración del reto
 // Inicializamos participantes
  const cardRef = useRef(null);

  const retos = [
    { icono: <FaLeaf />, texto: 'Planta un árbol en tu comunidad.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Planta un árbol en tu comunidad.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla todo el papel que puedas durante una semana.', dificultad: 'Moderada' },
    { icono: <FaLeaf />, texto: 'Planta un árbol en tu comunidad.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla todo el papel que puedas durante una semana.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Participa en una actividad de reforestación local.', dificultad: 'Difícil' },
    { icono: <FaLeaf />, texto: 'Crea tu propio jardín de hierbas en casa y úsalas en tus comidas.', dificultad: 'Moderada' },
    { icono: <FaRecycle />, texto: 'Compra productos reciclados y apoya empresas sostenibles.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Convierte una botella de plástico en una maceta para plantas.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Participa en una limpieza comunitaria en un parque local.', dificultad: 'Moderada' },
    { icono: <FaRecycle />, texto: 'Haz compost con tus desechos orgánicos.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Compra productos locales y de temporada para reducir la huella de carbono.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Apaga las luces y dispositivos electrónicos cuando no los uses.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla aparatos electrónicos antiguos en un centro de reciclaje especializado.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Adopta una planta en peligro de extinción y contribuye a su preservación.', dificultad: 'Difícil' },
    { icono: <FaLeaf />, texto: 'Utiliza bolsas reutilizables en lugar de bolsas de plástico.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Organiza un intercambio de ropa con amigos para reducir el desperdicio.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Instala bombillas LED en tu hogar para ahorrar energía.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Construye un comedero para pájaros reciclado.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recoge basura en la playa o en un área natural cercana.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Aprende sobre alimentos silvestres comestibles en tu área y prueba alguno.', dificultad: 'Moderada' },
    { icono: <FaLeaf />, texto: 'Utiliza productos de limpieza ecológicos en tu hogar.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Compra a granel para reducir el uso de envases.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Participa en una jornada de siembra de árboles en tu comunidad.', dificultad: 'Difícil' },
    { icono: <FaLeaf />, texto: 'Reduce el consumo de carne y prueba recetas vegetarianas.', dificultad: 'Moderada' },
    { icono: <FaRecycle />, texto: 'Recicla tu viejo teléfono móvil o tablet.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Usa pañuelos de tela en lugar de pañuelos de papel desechables.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Participa en un día sin coche y utiliza medios de transporte sostenibles.', dificultad: 'Moderada' },
    { icono: <FaRecycle />, texto: 'Recicla envases de plástico y cartón correctamente.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Planta flores que atraigan a polinizadores como abejas y mariposas.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Repara y reutiliza objetos en lugar de desecharlos.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla pilas y baterías de forma segura.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Construye un comedero para insectos y contribuye a la biodiversidad.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Participa en un voluntariado ambiental en tu área.', dificultad: 'Moderada' },
    { icono: <FaRecycle />, texto: 'Compra productos hechos de materiales reciclados.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Invierte en una botella de agua reutilizable y evita las de un solo uso.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Cocina con ingredientes locales y de temporada.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla los cartuchos de tinta de tus impresoras.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Usa toallas de tela en lugar de toallas de papel desechables.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Apaga el grifo mientras te cepillas los dientes para ahorrar agua.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla latas de aluminio y vidrio.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Crea tu propio compost con restos de alimentos.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Compra productos de limpieza a granel y rellena tus envases.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla y dona ropa que ya no uses.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Participa en una campaña de limpieza de playas o ríos.', dificultad: 'Moderada' },
    { icono: <FaLeaf />, texto: 'Construye un comedero para aves con materiales reciclados.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla electrodomésticos antiguos de manera adecuada.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Planta flores nativas para promover la biodiversidad.', dificultad: 'Fácil' },
    { icono: <FaLeaf />, texto: 'Usa bombillas de bajo consumo energético.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla el papel de regalo y las tarjetas de felicitación.', dificultad: 'Fácil' },
    { icono: <FaTree />, texto: 'Participa en una iniciativa de limpieza urbana en tu ciudad.', dificultad: 'Moderada' },
    { icono: <FaLeaf />, texto: 'Compra productos de belleza y cuidado personal eco-friendly.', dificultad: 'Fácil' },
    { icono: <FaRecycle />, texto: 'Recicla neumáticos usados de manera responsable.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Planta árboles frutales en tu jardín o comunidad.', dificultad: 'Moderada' },
    { icono: <FaTree />, texto: 'Planta árboles frutales en tu jardín o comunidad.', dificultad: 'Moderada' },
  ];

  useEffect(() => {
    obtenerRetoAleatorio();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Actualiza los días restantes cada 24 horas
      setDiasRestantes((prevDays) => (prevDays > 0 ? prevDays - 1 : 0));
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const obtenerRetoAleatorio = () => {
    const indiceAleatorio = Math.floor(Math.random() * retos.length);
    setRetoActual(retos[indiceAleatorio]);
    setParticipantes(participantes + 1);
  };

  const descargarImagen = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current, { scale: 2 }).then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'reto_sostenible.png';
        link.click();
      });
    }
  };

  const compartirEnRedesSociales = (redSocial) => {
    // Implementa la lógica para compartir en otras redes sociales
    // Puedes usar bibliotecas como react-share para facilitar esto
    alert(`Compartiendo en ${redSocial}...`);
  };

  return (
    <IconContext.Provider value={{ size: '2em' }}>
      <div className="min-h-screen flex flex-col items-center justify-center bg-teal-500 text-white">
        <div
          ref={cardRef}
          className="reto-aleatorio p-8 bg-teal-700 text-white rounded-md shadow-md max-w-md mx-auto relative overflow-hidden"
        >
          <h2 className="text-3xl font-bold mb-4 text-center">¡Reto de Sostenibilidad!</h2>

          <div className="reto-actual mb-6 bg-teal-600 p-4 rounded-md shadow-md">
            <h3 className="text-lg font-semibold mb-2">Reto Actual:</h3>
            <div className="flex items-center mb-2">
              <span className="text-green-500 mr-2">{retoActual?.icono}</span>
              <span className="font-semibold">{retoActual?.texto}</span>
            </div>
            <div className="text-gray-200 text-sm">Dificultad: {retoActual?.dificultad}</div>
          </div>

          <div className="flex items-center justify-between text-sm text-gray-400">
            <div>
              <p>Fecha de inicio: 01/01/2023</p>
              <p>Fecha límite: 31/01/2023</p>
            </div>
            <div>
              <p>Participantes: {participantes}</p>
              <p>Premio: Reconocimiento por ser eco-amigable</p>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Consejos para el Éxito:</h3>
            <ul className="list-disc pl-6">
              <li>Comparte tu progreso en las redes sociales con el hashtag #RetoEcoAmigable</li>
              <li>Invita a tus amigos a unirse al reto para hacerlo más divertido</li>
              <li>Investiga más sobre el tema para maximizar tu impacto sostenible</li>
            </ul>
          </div>

          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Premios Adicionales:</h3>
            <p>
              Participa para tener la oportunidad de ganar premios adicionales, como descuentos en productos
              sostenibles, cupones para actividades ecológicas y más.
            </p>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Comentarios y Experiencias:</h3>
            {/* Aquí puedes agregar un componente de comentarios o utilizar una biblioteca como disqus */}
            <p>¡Comparte tu experiencia y motivación para inspirar a otros participantes!</p>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Días Restantes:</h3>
            <p>{diasRestantes} días para completar el reto</p>
          </div>
        </div>

        <div className="mt-4 flex space-x-4">
          <button
            onClick={descargarImagen}
            className="bg-gradient-to-r from-yellow-300 to-pink-500 text-white py-2 px-4 rounded-md hover:from-yellow-500 hover:to-pink-600 transition-all"
          >
            Descargar Imagen
          </button>
          <button
            onClick={obtenerRetoAleatorio}
            className="bg-teal-500 text-white py-2 px-4 rounded-md hover:bg-teal-800 transition-all"
          >
            Obtener Nuevo Reto
          </button>
        </div>

        <div className="mt-4 text-center text-sm text-gray-400">
          <p>
            ¡Demuestra tu compromiso con la sostenibilidad y únete a {participantes} personas! Comparte tu reto en tus
            redes sociales.
          </p>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default RetoAleatorio;