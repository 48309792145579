import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const GananciasReporte = () => {
  const [datosBrokers, setDatosBrokers] = useState(() => {
    const datosGuardados = JSON.parse(localStorage.getItem('datosBrokers'));
    return datosGuardados || generarDatosIniciales();
  });

  const [filtroEspecializacion, setFiltroEspecializacion] = useState('Todas');
  const [ordenAscendente, setOrdenAscendente] = useState(false);
  const [pagina, setPagina] = useState(1);
  const resultadosPorPagina = 4;
  const [actualizando, setActualizando] = useState(false);

  const brokersFiltrados = datosBrokers.filter(
    (broker) => filtroEspecializacion === 'Todas' || broker.especializacion === filtroEspecializacion
  );

  const ordenarBrokers = () => {
    const brokersOrdenados = [...datosBrokers];
    brokersOrdenados.sort((a, b) => (ordenAscendente ? a.apr - b.apr : b.apr - a.apr));
    setDatosBrokers(brokersOrdenados);
    setOrdenAscendente(!ordenAscendente);
  };

  const simularActualizacionAPR = () => {
    setActualizando(true);
    setTimeout(() => {
      setDatosBrokers((prevBrokers) =>
        prevBrokers.map((broker) => ({
          ...broker,
          apr: getRandomAPR(),
        }))
      );
      setActualizando(false);
    }, 1000);
  };

  const cambiarPagina = (numeroPagina) => {
    setPagina(numeroPagina);
  };

  useEffect(() => {
    localStorage.setItem('datosBrokers', JSON.stringify(datosBrokers));
  }, [datosBrokers]);

  useEffect(() => {
    const interval = setInterval(() => {
      simularActualizacionAPR();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const indiceInicio = (pagina - 1) * resultadosPorPagina;
  const indiceFin = indiceInicio + resultadosPorPagina;
  const brokersPaginaActual = brokersFiltrados.slice(indiceInicio, indiceFin);

  // Ordenar brokers por APR de mayor a menor
  const topBrokers = [...datosBrokers].sort((a, b) => b.apr - a.apr).slice(0, 3);

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gradient-to-br from-teal-600 to-teal-500 text-white">
      <div className="max-w-screen-md w-full p-8 rounded-lg shadow-lg bg-teal-800">
        <h2 className="text-4xl font-bold mb-6 text-center">Reporte de Rentabilidad</h2>

        {/* Filtro por especialización */}
        <div className="mb-6 flex items-center justify-center">
          <label className="text-white mr-2">Filtrar por Especialización:</label>
          <select
            className="p-2 bg-teal-500 text-white border border-teal-600 rounded"
            onChange={(e) => setFiltroEspecializacion(e.target.value)}
            value={filtroEspecializacion}
          >
            <option value="Todas">Todas</option>
            <option value="Forex">Forex</option>
            <option value="Acciones">Acciones</option>
            <option value="Criptomonedas">Criptomonedas</option>
            <option value="Materias Primas">Materias Primas</option>
          </select>
        </div>

        {/* Tabla de brokers */}
        <table className="min-w-full mb-6">
          <thead>
            <tr>
              <th className="text-left cursor-pointer" onClick={ordenarBrokers}>
                Broker{' '}
                <FontAwesomeIcon icon={ordenAscendente ? faSortUp : faSortDown} />
              </th>
              <th className="text-left cursor-pointer" onClick={ordenarBrokers}>
                Nacionalidad
              </th>
              <th className="text-left cursor-pointer" onClick={ordenarBrokers}>
                Especialización
              </th>
              <th className="text-left cursor-pointer" onClick={ordenarBrokers}>
                APR (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {brokersPaginaActual.map((broker) => (
              <tr
                key={broker.id}
                className={`odd:bg-teal-900 even:bg-teal-800 hover:bg-teal-700 cursor-pointer transition-all`}
              >
                <td>{broker.nombre}</td>
                <td>{broker.nacionalidad}</td>
                <td>{broker.especializacion}</td>
                <td>{(broker.apr * 100).toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Paginación */}
        <div className="flex justify-end">
          <div className="flex items-center">
            <span className="mr-2 text-white">Página:</span>
            {Array.from({ length: Math.ceil(brokersFiltrados.length / resultadosPorPagina) }, (_, i) => (
              <button
                key={i}
                className={`p-2 mx-1 ${
                  pagina === i + 1 ? 'bg-teal-500 text-white' : 'hover:bg-teal-500 hover:text-white'
                } rounded`}
                onClick={() => cambiarPagina(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>

        {/* Información adicional */}
        <div className="mt-6 text-sm text-gray-400">
          <p>
            Los brokers deben desarrollar informes mensuales. Cada operación de trading será grabada para una mayor seguridad en los fondos de los clientes.
          </p>
        </div>

        {/* Top Brokers */}
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-4">Top Brokers</h3>
          <ul className="list-disc list-inside">
            {topBrokers.map((broker) => (
              <li key={broker.id} className="text-teal-300">
                {broker.nombre} - APR: {(broker.apr * 100).toFixed(2)}%
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Función para obtener un APR aleatorio entre 1% y 5%
const getRandomAPR = () => {
  return Math.random() * (0.05 - 0.01) + 0.01; // Entre 1% y 5%
};

const generarDatosIniciales = () => {
  return [
    { id: 1, nombre: '#sv/01', nacionalidad: 'EE. UU.', especializacion: 'Forex', apr: getRandomAPR() },
    { id: 2, nombre: '#sv/02', nacionalidad: 'Reino Unido', especializacion: 'Acciones', apr: getRandomAPR() },
    { id: 3, nombre: '#sv/03', nacionalidad: 'Canadá', especializacion: 'Criptomonedas', apr: getRandomAPR() },
    { id: 4, nombre: '#sv/04', nacionalidad: 'Australia', especializacion: 'Materias Primas', apr: getRandomAPR() },
    { id: 5, nombre: '#sv/05', nacionalidad: 'España.', especializacion: 'Criptomonedas', apr: getRandomAPR() },
    { id: 6, nombre: '#sv/06', nacionalidad: 'España', especializacion: 'Acciones', apr: getRandomAPR() },
    { id: 7, nombre: '#sv/07', nacionalidad: 'EE. UU', especializacion: 'Criptomonedas', apr: getRandomAPR() },
    { id: 8, nombre: '#sv/08', nacionalidad: 'Portugal', especializacion: 'Materias Primas', apr: getRandomAPR() },
  ];
};

export default GananciasReporte;
