import React, { useState } from 'react';
import { FaCopy, FaEthereum, FaBitcoin } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';

const DonationAddressCard = () => {
  const [copied, setCopied] = useState('');
  const [copiedAddress, setCopiedAddress] = useState('');
  const [showAddresses, setShowAddresses] = useState(true);

  const addresses = {
    ethereum: '0x905e500b89c914ac7ab88b79fdf349825ceb2879',
    bitcoin: 'bc1qyx8muektf556ptjn4nd6mpgkuewmw7dwz7300n'
  };

  const [props, set] = useSpring(() => ({
    opacity: 0,
    transform: 'scale(0.95)',
    config: { tension: 200, friction: 12 }
  }));

  const copyToClipboard = (address, type) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(address).then(() => {
        setCopied(type);
        setCopiedAddress(address);
        set({ opacity: 1, transform: 'scale(1)' });
        setTimeout(() => {
          set({ opacity: 0, transform: 'scale(0.95)' });
        }, 2000);
      }).catch(() => {
        alert('Failed to copy address.');
      });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = address;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(type);
        setCopiedAddress(address);
        set({ opacity: 1, transform: 'scale(1)' });
        setTimeout(() => {
          set({ opacity: 0, transform: 'scale(0.95)' });
        }, 2000);
      } catch (err) {
        alert('Failed to copy address.');
      } finally {
        document.body.removeChild(textArea);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold mb-6 text-center text-gray-100">Donativos</h2>

        {/* Botón para Mostrar/Ocultar Direcciones */}
        <button
          onClick={() => setShowAddresses(!showAddresses)}
          className="w-full p-2 mb-6 bg-teal-300 text-white rounded-md hover:bg-teal-400 transition duration-300"
        >
          {showAddresses ? 'Ocultar Direcciones' : 'Mostrar Direcciones'}
        </button>

        {showAddresses && (
          <>
            {/* Sección Ethereum */}
            <section className="mb-8">
              <h3 className="text-2xl font-semibold mb-2 flex items-center">
                <FaEthereum className="mr-2 text-blue-500" />
                Dirección Global (BEP20)
              </h3>
              <div className="flex items-center bg-gray-700 p-4 rounded-md shadow-md relative group">
                <p className="flex-1 overflow-hidden truncate">{addresses.ethereum}</p>
                <button
                  onClick={() => copyToClipboard(addresses.ethereum, 'ethereum')}
                  className="ml-4 p-2 bg-teal-300 text-white rounded-md hover:bg-teal-400 transition duration-300 relative"
                >
                  <FaCopy />
                  {copied === 'ethereum' && (
                    <animated.div style={props} className="absolute -top-10 right-0 bg-teal-400 text-white p-2 rounded-md shadow-md text-sm">
                      ¡Copiado!
                    </animated.div>
                  )}
                </button>
              </div>
            </section>

            {/* Sección Bitcoin */}
            <section className="mb-8">
              <h3 className="text-2xl font-semibold mb-2 flex items-center">
                <FaBitcoin className="mr-2 text-yellow-500" />
                Dirección Bitcoin (BTC)
              </h3>
              <div className="flex items-center bg-gray-700 p-4 rounded-md shadow-md relative group">
                <p className="flex-1 overflow-hidden truncate">{addresses.bitcoin}</p>
                <button
                  onClick={() => copyToClipboard(addresses.bitcoin, 'bitcoin')}
                  className="ml-4 p-2 bg-teal-300 text-white rounded-md hover:bg-teal-400 transition duration-300 relative"
                >
                  <FaCopy />
                  {copied === 'bitcoin' && (
                    <animated.div style={props} className="absolute -top-10 right-0 bg-teal-400 text-white p-2 rounded-md shadow-md text-sm">
                      ¡Copiado!
                    </animated.div>
                  )}
                </button>
              </div>
            </section>
          </>
        )}

        {/* Instrucciones */}
        <section className="mb-8 text-gray-400 text-sm">
          <h4 className="text-xl font-semibold mb-2">Instrucciones</h4>
          <p>
            Haz clic en el botón de copiar junto a la dirección de la criptomoneda que deseas utilizar. La dirección será copiada al portapapeles y podrás pegarla en tu billetera para realizar un donativo. Si necesitas más ayuda, no dudes en contactarnos.
          </p>
        </section>

        {/* Agradecimientos */}
        <section className="text-gray-400 text-sm mb-8">
          <h4 className="text-xl font-semibold mb-2">Agradecimientos</h4>
          <p>
            ¡Gracias por tu apoyo! Cada donativo es muy apreciado y nos ayuda a seguir adelante. Tu generosidad marca la diferencia en nuestro proyecto.
          </p>
        </section>
      </div>
    </div>
  );
};

export default DonationAddressCard;
