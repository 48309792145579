// TradeForm.js
import React, { useState } from 'react';

const TradeForm = () => {
    const [formData, setFormData] = useState({
        transactionType: 'normal',
        cryptocurrency: '',
        amount: '',
        price: '',
        stopPrice: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes implementar la lógica para realizar la compra o venta
        console.log(formData);
        // Resetear el formulario después de enviar
        setFormData({
            transactionType: 'normal',
            cryptocurrency: '',
            amount: '',
            price: '',
            stopPrice: '',
        });
    };

    return (
        <div className="min-h-screen bg-gray-900 flex flex-col justify-end">
            <div className="flex flex-row justify-end p-4">
                <div className="bg-gray-800 rounded-lg p-4 w-full sm:w-1/4 mb-4 sm:mb-0">
                    <h3 className="text-lg font-semibold mb-2 text-gray-300">Información</h3>
                    <p className="text-gray-300">sin conexion</p>
                </div>
            </div>
            <div className="flex flex-row justify-end p-4">
                <div className="bg-gray-800 rounded-lg p-6 w-full sm:w-1/4">
                    <h2 className="text-3xl font-bold text-center mb-4 text-teal-300">SVFinancex</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="transactionType" className="block text-sm font-medium text-gray-300 mb-1">Tipo de Transacción</label>
                            <select name="transactionType" id="transactionType" value={formData.transactionType} onChange={handleChange} className="w-full py-2 px-4 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                <option value="normal">Normal</option>
                                <option value="limit">Límite (Precio)</option>
                                <option value="stop_limit">Stop Limit (Precio / Precio de Stop)</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="cryptocurrency" className="block text-sm font-medium text-gray-300 mb-1">Criptomoneda</label>
                            <input type="text" name="cryptocurrency" id="cryptocurrency" placeholder="Ej: Bitcoin" value={formData.cryptocurrency} onChange={handleChange} className="w-full py-2 px-4 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                        </div>
                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-300 mb-1">Cantidad</label>
                            <input type="number" name="amount" id="amount" placeholder="Ej: 1.5" value={formData.amount} onChange={handleChange} className="w-full py-2 px-4 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                        </div>
                        <div>
                            <label htmlFor="price" className="block text-sm font-medium text-gray-300 mb-1">{formData.transactionType === 'stop_limit' ? 'Precio Limit' : 'Precio'}</label>
                            <input type="number" name="price" id="price" placeholder="Ej: 50000" value={formData.price} onChange={handleChange} className="w-full py-2 px-4 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                        </div>
                        {formData.transactionType === 'stop_limit' && (
                            <div>
                                <label htmlFor="stopPrice" className="block text-sm font-medium text-gray-300 mb-1">Precio de Stop</label>
                                <input type="number" name="stopPrice" id="stopPrice" placeholder="Ej: 49000" value={formData.stopPrice} onChange={handleChange} className="w-full py-2 px-4 rounded-md bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                            </div>
                        )}
                        <div className="flex flex-col sm:flex-row mt-4">
                            <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-500 w-full mb-2 sm:mb-0 sm:mr-2">
                                Comprar
                            </button>
                            <button type="submit" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500 w-full">
                                Vender
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TradeForm;
