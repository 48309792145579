import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Profile from './Profile';
import TransactionHistory from './TransactionHistory';
import GananciasPerdidas from './GananciasPerdidas';
import AffiliatesHome from '../Pages/AffiliatesPanel/AffiliatesHome';

const Overview = () => (
  <div className="p-4 bg-white rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">Resumen del Dashboard</h2>
    {/* Contenido de la sección de resumen */}
  </div>
);

const Dashboard = () => {
  return (
    <div className="bg-gray-900 min-h-screen flex">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-3xl font-bold mb-6">Mi Dashboard</h1>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="TransactionHistory" element={<TransactionHistory />} />
          <Route path="GananciasPerdidas" element={<GananciasPerdidas />} />
          <Route path="AffiliatesHome" element={<AffiliatesHome />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;