import React, { useState } from 'react';
import { BsGraphUp, BsGraphDown } from 'react-icons/bs';
import ClipLoader from 'react-spinners/ClipLoader';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

const TotalEarningsCard = ({ percentageChange, prevEarnings }) => {
  const [earnings, setEarnings] = useState('');
  const [initialInvestment, setInitialInvestment] = useState('');
  const [investmentName, setInvestmentName] = useState('');
  const [showData, setShowData] = useState(false);
  const [inputError, setInputError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEarningsChange = (e) => setEarnings(e.target.value);
  const handleInitialInvestmentChange = (e) => setInitialInvestment(e.target.value);
  const handleNameChange = (e) => setInvestmentName(e.target.value);

  const calculateROI = () => {
    const earningsValue = parseFloat(earnings);
    const initialInvestmentValue = parseFloat(initialInvestment);
    if (isNaN(initialInvestmentValue) || initialInvestmentValue <= 0) {
      return 0;
    }
    const roiValue = ((earningsValue / initialInvestmentValue) * 100);
    return roiValue;
  };

  const roi = calculateROI();
  const roiDisplay = Math.min(roi, 100);
  const roiColor = roi >= 0 ? 'text-green-400' : 'text-red-400';
  const roiIcon = roi >= 0 ? <BsGraphUp className="text-green-400" /> : <BsGraphDown className="text-red-400" />;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      showResults();
    }
  };

  const showResults = () => {
    if (earnings && initialInvestment && investmentName) {
      setShowData(true);
      setInputError('');
    } else {
      setInputError('Por favor, complete todos los campos.');
    }
  };

  const generateAndDownloadImage = () => {
    setLoading(true);
    html2canvas(document.querySelector("#summary")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      saveAs(imgData, 'investment-summary.png');
      setLoading(false);
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center p-4">
      <div className="max-w-xs md:max-w-sm lg:max-w-md w-full bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Detalles de Inversión</h2>
        <div className="mb-6">
          <label className="block mb-2 text-gray-300" htmlFor="investmentName">
            Nombre de la Inversión:
          </label>
          <input
            type="text"
            id="investmentName"
            value={investmentName}
            onChange={handleNameChange}
            placeholder="Ingrese el nombre de la inversión"
            className="w-full p-2 rounded-md bg-gray-700 border border-gray-600 text-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-300 transition duration-300"
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-300" htmlFor="initialInvestment">
            Inversión Inicial:
          </label>
          <input
            type="number"
            id="initialInvestment"
            value={initialInvestment}
            onChange={handleInitialInvestmentChange}
            onKeyPress={handleKeyPress}
            placeholder="Ingrese la inversión inicial"
            className="w-full p-2 rounded-md bg-gray-700 border border-gray-600 text-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-300 transition duration-300"
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-gray-300" htmlFor="earnings">
            Ganancias:
          </label>
          <input
            type="number"
            id="earnings"
            value={earnings}
            onChange={handleEarningsChange}
            onKeyPress={handleKeyPress}
            placeholder="Ingrese las ganancias"
            className="w-full p-2 rounded-md bg-gray-700 border border-gray-600 text-gray-100 focus:outline-none focus:ring-2 focus:ring-teal-300 transition duration-300"
          />
          {inputError && <p className="text-red-500 mt-2 text-sm">{inputError}</p>}
        </div>
        <div className="mb-6">
          <button
            onClick={showResults}
            className="flex items-center bg-teal-300 text-white px-4 py-2 rounded-lg hover:bg-teal-400 transition duration-300 focus:outline-none focus:ring-2 focus:ring-teal-300"
          >
            Mostrar Datos
          </button>
        </div>
        {showData && (
          <>
            <div className="flex items-center mb-4">
              <p className="text-4xl font-bold">${earnings}</p>
              <div className={`ml-4 flex items-center px-2 py-1 rounded-md ${roiColor}`}>
                {roiIcon}
                <span className="ml-2">{roiDisplay.toFixed(2)}%</span>
              </div>
            </div>
            <p className="mb-4 text-sm text-gray-400">Comparado con el mes anterior: ${prevEarnings}</p>
            <div className="mb-6 text-gray-400">
              <p><strong>ROI:</strong> <span className={roiColor}>{roiDisplay.toFixed(2)}%</span></p>
            </div>
            <button
              onClick={generateAndDownloadImage}
              className="flex items-center bg-teal-300 text-white px-4 py-2 rounded-lg hover:bg-teal-400 transition duration-300 focus:outline-none focus:ring-2 focus:ring-teal-300"
              disabled={loading}
            >
              {loading ? <ClipLoader size={16} color="#ffffff" /> : 'Descargar Imagen'}
            </button>
            <div id="summary" className="bg-gray-800 p-6 rounded-lg mt-4 shadow-xl relative">
              <div className="absolute inset-0 bg-gradient-to-br from-teal-300 to-pink-400 opacity-20 rounded-lg z-0"></div>
              <div className="relative z-10 text-center">
                <h1 className="text-3xl font-bold text-gray-100 mb-4">svfinancex.com</h1>
                <h3 className="text-2xl font-bold text-gray-100 mb-2">{investmentName}</h3>
                <div className="bg-gray-900 p-4 rounded-lg shadow-lg border-4 border-teal-300">
                  <p className="text-4xl font-bold text-teal-300 mb-4">${earnings}</p>
                  <p className="text-lg text-gray-400 mb-2">Ganancias: ${earnings}</p>
                  <p className="text-lg text-gray-400 mb-2">Inversión Inicial: ${initialInvestment}</p>
                  <p className="text-lg text-gray-400 mb-2">
                    ROI: <span className={roiColor}>{roiDisplay.toFixed(2)}%</span>
                  </p>
                  <p className={`text-lg font-semibold ${roiColor}`}>
                    {roi >= 0 ? '▲' : '▼'} {roiDisplay.toFixed(2)}%
                  </p>
                </div>
                <div className="mt-4 p-4 bg-pink-400 rounded-lg shadow-lg">
                  <p className="text-lg text-gray-800">¡Tu ROI ha sido calculado exitosamente!</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TotalEarningsCard;
