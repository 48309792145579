import React from 'react';

const traders = [
  { id: 1, name: 'Trader 1', profit: '$1500' },
  { id: 2, name: 'Trader 2', profit: '$1200' },
  { id: 3, name: 'Trader 3', profit: '$1000' },
];

const TopTraders = () => {
  return (
    <div className="bg-gray-900 text-white">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold text-center mb-8">Top Traders</h1>
        <div className="flex justify-center">
          {traders.map((trader, index) => (
            <div
              key={trader.id}
              className={`bg-white shadow-md rounded-lg p-6 m-4 ${
                index === 0 ? 'transform scale-110' : ''
              }`}
            >
              <div className="text-2xl font-semibold text-center mb-2">
                #{index + 1}
              </div>
              <div className="text-xl text-center">{trader.name}</div>
              <div className="text-center text-teal-300">{trader.profit}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopTraders;
