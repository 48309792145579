import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faCheckCircle, faUserGraduate, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Link as ScrollLink } from 'react-scroll';

const TraderCertification = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen py-12">
      <div className="container mx-auto px-4">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-teal-300 mb-4">Cómo Convertirte en un Trader Certificado</h1>
          <p className="text-xl mb-6">Sigue estos pasos y completa las pruebas para unirte a nuestra plataforma.</p>
          <ScrollLink
            to="steps"
            smooth={true}
            duration={1000}
            className="bg-teal-500 text-white px-6 py-3 rounded-md cursor-pointer hover:bg-teal-400 transition duration-300"
          >
            Empezar
          </ScrollLink>
        </header>

        <section id="requirements" className="bg-gray-900 p-8 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-semibold text-teal-300 mb-6">Requisitos para Aplicar</h2>
          <ul className="list-disc list-inside space-y-4 text-lg">
            <li>Ser mayor de 18 años.</li>
            <li>Tener al menos 5 años de experiencia demostrable en trading.</li>
            <li>Comprometerse a completar todos los exámenes y pruebas requeridas.</li>
            <li>Disponer de una conexión a internet estable y fluida.</li>
          </ul>
        </section>

        <section id="steps" className="bg-gray-900 p-8 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-semibold text-teal-300 mb-6">Pasos a Seguir</h2>
          <ol className="list-decimal list-inside space-y-6 text-lg">
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faUserGraduate} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Solicitar Certificación</h3>
                <p>Envía tu solicitud de certificación a solicitudtraders@svfinancex.com</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faClipboardList} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Verificación de Identidad</h3>
                <p>Sube una copia de tu documento de identidad al correo proporcionado.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faUserGraduate} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Examen de Certificación</h3>
                <p>Participa en nuestro examen de certificación que evalúa tu conocimiento y habilidades en trading.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faClipboardList} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Examen de Conocimiento</h3>
                <p>Realiza y aprueba el examen de conocimiento que cubre todos los aspectos esenciales del trading.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faUserGraduate} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Prueba de Habilidades Prácticas</h3>
                <p>Demuestra tus habilidades prácticas mediante operaciones simuladas en nuestra plataforma de pruebas.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faQuestionCircle} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Entrevista Final</h3>
                <p>Participa en una entrevista final para evaluar tu preparación y resolver cualquier duda adicional.</p>
              </div>
            </li>
          </ol>
        </section>

        <section id="tests" className="bg-gray-900 p-8 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-semibold text-teal-300 mb-6">Pruebas Solicitadas</h2>
          <ul className="list-disc list-inside space-y-6 text-lg">
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faClipboardList} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Evaluación Teórica</h3>
                <p>Examen escrito que abarca análisis técnico, análisis fundamental y gestión de riesgos.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Simulaciones de Trading</h3>
                <p>Pruebas prácticas para evaluar tu capacidad de operar en condiciones simuladas de mercado.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faClipboardList} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Presentación de Estrategias</h3>
                <p>Presenta y defiende tus estrategias de trading ante un panel de expertos.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faQuestionCircle} className="text-teal-300 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-teal-300">Entrevista Personal</h3>
                <p>Entrevista para discutir resultados, experiencias previas y motivación para unirse a nuestra plataforma.</p>
              </div>
            </li>
          </ul>
        </section>

        <section id="benefits" className="bg-gray-900 p-8 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-semibold text-teal-300 mb-6">Beneficios de ser un Trader Certificado</h2>
          <ul className="list-disc list-inside space-y-4 text-lg">
            <li>Acceso a herramientas avanzadas de trading.</li>
            <li>Control sobre los fondos de los clientes para invertir en mercados financieros.</li>
            <li>Participación en competiciones y eventos exclusivos.</li>
            <li>Generar ingresos tanto activos como pasivos.</li>
          </ul>
        </section>

        <section id="resources" className="bg-gray-900 p-8 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-semibold text-teal-300 mb-6">Recursos Adicionales</h2>
          <ul className="list-disc list-inside space-y-4 text-lg">
            <li>
              <a href="/InvestmentPoolsPage" className="text-teal-400 hover:text-teal-300 transition duration-300">
                Pools de Inversión
              </a>
            </li>
            <li>
              <a href="/InvestmentHome" className="text-teal-400 hover:text-teal-300 transition duration-300">
                Invertir
              </a>
            </li>
            <li>
              <a href="/BrokersPage" className="text-teal-400 hover:text-teal-300 transition duration-300">
                Traders Certificados
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default TraderCertification;
