// Importar las bibliotecas necesarias
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

// Componente de formulario
const FormField = ({ label, icon, type, id, name, value, onChange, error, required }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {label}
    </label>
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={`mt-1 p-2 border rounded-md w-full ${error ? 'border-red-500' : ''} bg-gray-700 text-gray-300`}
      required={required}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

// Componente principal de Entrar
const Entrar = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const [message, setMessage] = useState({ text: '', isSuccess: false });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};

    if (!usernameOrEmail.trim()) {
      errors.usernameOrEmail = 'Nombre de usuario o correo electrónico es obligatorio';
    }

    if (!password.trim()) {
      errors.password = 'Contraseña es obligatoria';
    }

    setMessage({ text: '', isSuccess: false }); // Limpiar mensajes anteriores
    return Object.keys(errors).length === 0;
  };

  const handleLogin = async () => {
    try {
      setLoading(true);

      const response = await fetch('http://localhost:8000/api/v1/users/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username_or_email: usernameOrEmail, password }),
      });

      if (response.ok) {
        setMessage({ text: '¡Inicio de sesión exitoso! Redirigiendo...', isSuccess: true });
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } else {
        const data = await response.json();
        setMessage({ text: data.error || 'Error al iniciar sesión', isSuccess: false });
      }
    } catch (error) {
      console.error('Error de red al iniciar sesión:', error.message);
      setMessage({ text: 'Error de red al iniciar sesión', isSuccess: false });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && !loading) {
      handleLogin();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-900 p-6 rounded-md shadow-md max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Inicio de Sesión</h1>
        {message.text && (
          <div className={`mb-4 p-2 rounded text-center ${message.isSuccess ? 'bg-green-600 text-white' : 'bg-red-600 text-white border border-red-700'}`}>
            {message.text}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <FormField
            label="Nombre de usuario o correo electrónico"
            icon={faEnvelope}
            type="text"
            id="usernameOrEmail"
            name="usernameOrEmail"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            error={message.isSuccess ? '' : message.text || ''}
            required
          />
          <FormField
            label="Contraseña"
            icon={faLock}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={message.isSuccess ? '' : message.text || ''}
            required
          />
          <button
            type="submit"
            className={`bg-teal-500 text-black px-4 py-2 rounded-md hover:bg-teal-600 focus:outline-none focus:border-teal-700 focus:ring focus:ring-blue-200 ${loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={loading}
          >
            {loading ? 'Cargando...' : 'Iniciar Sesión'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Entrar;
