import React, { useState, useEffect } from 'react';
import { FaRocket, FaChartLine, FaGift, FaClock, FaTimes, FaInfoCircle, FaExclamationTriangle, FaCrown, FaTrophy, FaShieldAlt, FaExpand, FaCompress } from 'react-icons/fa';

const AnuncioLanzamientoExchange = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isVisible, setIsVisible] = useState(true);
  const [isCritical, setIsCritical] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const tiempoRestante = calculateTimeLeft();
      setTimeLeft(tiempoRestante);

      if (tiempoRestante.días === 0 && tiempoRestante.horas <= 1) {
        setIsCritical(true); // Cambia a modo crítico si queda menos de 1 hora.
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const difference = +new Date("2024-10-02T00:00:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        días: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / 1000 / 60) % 60),
        segundos: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  function handleFullscreenToggle() {
    setIsFullscreen(!isFullscreen);
  }

  function handleClose() {
    if (isCritical) {
      if (window.confirm("¿Estás seguro de que quieres cerrar la notificación? La actualización está a punto de comenzar.")) {
        setIsVisible(false);
      }
    } else {
      setIsVisible(false);
    }
  }

  if (!isVisible) return null;

  return (
    <div
      className={`fixed ${isFullscreen ? 'inset-0' : 'bottom-4 right-4'} max-w-xs w-full bg-gray-800 border-4 ${
        isCritical ? 'border-red-600 shadow-red-600' : 'border-pink-400 shadow-pink-500'
      } rounded-lg p-4 text-center shadow-xl transform transition-transform duration-500 ease-in-out ${isFullscreen ? 'animate-scaleIn' : 'animate-slideInBottom'} z-50`}
    >
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-3">
          <FaRocket className={`text-3xl ${isCritical ? 'text-red-600' : 'text-teal-300'} animate-pulse`} />
          <h2
            className="text-xl font-bold"
            style={{
              fontFamily: 'Permanent Marker, cursive',
              textShadow: `2px 2px ${isCritical ? '#ff4500' : '#ff1493'}`,
              color: isCritical ? '#ff4500' : '#00ffcc',
            }}
          >
            ¡Actualización a Exchange!
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          <button onClick={handleFullscreenToggle} className="text-pink-400 hover:text-teal-300">
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
          <button onClick={handleClose} className="text-pink-400 hover:text-teal-300">
            <FaTimes />
          </button>
        </div>
      </div>

      <p className="text-sm text-pink-400 mb-4" style={{ fontFamily: 'Courier New, monospace' }}>
        ¡Volvemos en Diciembre!
      </p>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {['días', 'horas', 'minutos', 'segundos'].map((unidad, index) => (
          <div key={index} className={`text-center relative p-2 rounded-lg border-2 ${
            isCritical ? 'border-red-600' : 'border-teal-300'
          }`}>
            <FaClock className={`text-2xl mb-1 animate-pulse ${isCritical ? 'text-red-600' : 'text-teal-300'}`} />
            <p className="text-pink-400 text-xs uppercase">{unidad}</p>
            <p className={`font-bold text-xl ${isCritical ? 'text-red-600' : 'text-teal-300'}`}>{timeLeft[unidad] || '0'}</p>
          </div>
        ))}
      </div>

      <div className="text-xs text-teal-300">
        <p className="flex items-center mb-2"><FaInfoCircle className="mr-2" /> 🎉 ¡Gana recompensas con cada operación!</p>
        <p className="flex items-center mb-2"><FaChartLine className="mr-2" /> 📈 Herramientas avanzadas de gráficos a tu alcance.</p>
        <p className="flex items-center mb-2"><FaShieldAlt className="mr-2" /> 🔒 Funciones de seguridad de primera clase.</p>
        <p className="flex items-center mb-2"><FaCrown className="mr-2" /> 🌍 Accesibilidad global con soporte multilingüe.</p>
        <p className="flex items-center mb-2"><FaGift className="mr-2" /> 🎁 Bonos exclusivos para nuevos usuarios.</p>
        <p className="flex items-center mb-2"><FaTrophy className="mr-2" /> 🏆 Competiciones con grandes premios.</p>
        <p className="flex items-center mb-2"><FaExclamationTriangle className="mr-2" /> ⚠️ Ofertas limitadas, ¡no te lo pierdas!</p>
      </div>
    </div>
  );
};

export default AnuncioLanzamientoExchange;
