import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Header from './Pages/Header';
import FormularioInversion from './Components/FormularioInversion';
import SegurosCapital from './Pages/SegurosCapital';
import GananciasReporte from './Pages/GananciasReporte';
import Proyectos from './Pages/Proyectos.';
import Footer from './Components/Footer';
import RetoAleatorio from './Components/RetoAleatorio';
import FAQ from './Pages/FAQ';
import Entrar from './Components/Entrar';
import Registro from './Routes/Registro'
import AffiliatesPage from './Pages/AfiliatesPage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Dashboard from './Components/Dashboard';
import Profile from './Components/Profile';
import TransactionHistory from './Components/TransactionHistory';
import GananciasPerdidas from './Components/GananciasPerdidas';
import BannerPrincipal from './Home/BannerPrincipal';
import TermsAndConditions from './Pages/TermsAndConditions';
import BrokersPage from './Pages/BrokersPage';
import CookieNotice from './Home/CookieNoticie';
import PolicyPage from './Pages/PolicyPage';
import Nosotros from './Pages/Nosotros';
import AffiliatesHome from './Pages/AffiliatesPanel/AffiliatesHome';
import PortfolioHome from './Pages/PortfolioPanel/PortfolioHome';
import TradingHome from './Pages/TradingPage/TradingHome';
import InvestmentPoolsPage from './Pages/InvestmentPoolsPage';
import PropertyList from './Pages/InvestmentPage/PropertyList';
import InvestmentHome from './Pages/InvestmentPage/InvestmentHome';
import Wallet from './Components/Wallet';
import TraderCertification from './Pages/TraderCertification';
import VetPage from './Components/VetPage';
import PoolsHome from './Pages/PoolsPage.js/PoolsHome';


const App = () => {
  const [currentPage, setCurrentPage] = useState('home');

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route
            path="/Home"
            element={<Home setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/FormularioInversion"
            element={<FormularioInversion setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/PoolsHome"
            element={<PoolsHome setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/VetPage"
            element={<VetPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/TraderCertification"
            element={<TraderCertification setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Wallet"
            element={<Wallet setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/InvestmentHome"
            element={<InvestmentHome setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/SegurosCapital"
            element={<SegurosCapital setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/RetoAleatorio"
            element={<RetoAleatorio setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Proyectos"
            element={<Proyectos setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Nosotros"
            element={<Nosotros setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/GananciasReporte"
            element={<GananciasReporte setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/PropertyList"
            element={<PropertyList setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/InvestmentPoolsPage"
            element={<InvestmentPoolsPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Entrar"
            element={<Entrar setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Registro"
            element={<Registro setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/FAQ"
            element={<FAQ setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/AfiliatesPage"
            element={<AffiliatesPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/PrivacyPolicy"
            element={<PrivacyPolicy setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Dashboard*"
            element={<Dashboard setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/Profile"
            element={<Profile setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/TransactionHistory"
            element={<TransactionHistory setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/GananciasPerdidas"
            element={<GananciasPerdidas setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/BannerPrincipal"
            element={<BannerPrincipal setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/TermsAndConditions"
            element={<TermsAndConditions setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/BrokersPage"
            element={<BrokersPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/AffiliatesHome"
            element={<AffiliatesHome setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/PortfolioHome"
            element={<PortfolioHome setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/TradingHome"
            element={<TradingHome setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/CookieNotice"
            element={<CookieNotice setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/PolicyPage"
            element={<PolicyPage setCurrentPage={setCurrentPage} />}
          />
          <Route
            path="/"
            element={
              <Home
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            }
          />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
