// src/components/AffiliatesPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

const AffiliatesPage = () => {
  const [formData, setFormData] = useState({ nombre: '', email: '', metodoPago: '' });
  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Realiza la solicitud POST al endpoint de tu API de Django
      const response = await axios.post('http://localhost:8000/affiliates/register/', formData);

      // Si la solicitud es exitosa, puedes manejar la respuesta según tus necesidades
      console.log('Respuesta del servidor:', response.data);

      // Lógica de envío exitoso
      setFormStatus({ type: 'success', message: 'Registro exitoso. ¡Bienvenido!' });
    } catch (error) {
      // Si hay un error en la solicitud, puedes manejarlo aquí
      console.error('Error al enviar el formulario:', error);

      // Lógica de envío con error
      setFormStatus({ type: 'error', message: 'Hubo un error al enviar el formulario.' });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="p-8 rounded-md shadow-md max-w-screen-md w-full bg-gray-800">
        <h1 className="text-4xl font-bold mb-6 text-teal-500">Programa de Afiliados</h1>
        <p className="text-lg mb-8">
          Únete a nuestro programa de afiliados y permite que traders profesionales inviertan tu dinero.
          Gana comisiones cada vez que refieres a nuevos inversores.
        </p>

        {/* Descripción del Programa de Afiliados */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Descripción del Programa</h2>
          <p className="mb-4">
            <strong>Visión General:</strong> Nuestro programa de afiliados te permite ganar dinero al promocionar nuestros servicios de trading profesional. Por cada cliente referido, recibirás una comisión generosa.
          </p>
          <p>
            <strong>Beneficios para Afiliados:</strong> Disfruta de ventajas exclusivas, incluyendo acceso a informes de rendimiento de traders, soporte personalizado y bonificaciones adicionales por alto volumen de conversiones.
          </p>
        </div>

        {/* Cómo Funciona */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Cómo Funciona</h2>
          <p className="mb-4">
            <strong>Proceso de Afiliación:</strong> Únete al programa en tres simples pasos: completa el formulario de registro, recibe tu enlace de afiliado único y comienza a promocionar nuestros servicios.
          </p>
          <p>
            <strong>Enlace de Afiliado:</strong> Utiliza tu enlace personalizado para rastrear conversiones. Cada vez que alguien se registra a través de tu enlace, ¡ganas comisiones!
          </p>
        </div>

        {/* Recompensas y Comisiones */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Recompensas y Comisiones</h2>
          <p className="mb-4">
            <strong>Estructura de Comisiones:</strong> Recibe un porcentaje generoso de las inversiones realizadas por los clientes referidos. Cuanto más refieras, ¡más ganas!
          </p>
          <p>
            <strong>Bonificaciones Adicionales:</strong> Obtén bonificaciones especiales por alcanzar objetivos de conversión significativos. Reconocemos y recompensamos tu éxito.
          </p>
        </div>

        {/* Material de Marketing */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Material de Marketing</h2>
          <p className="mb-4">
            <strong>Banners y Gráficos:</strong> Proporcionamos banners y gráficos atractivos para que los incluyas en tu sitio web, blog o redes sociales y aumentes tus conversiones.
          </p>
          <p>
            <strong>Texto de Marketing:</strong> Accede a ejemplos de textos promocionales efectivos. Personalízalos para adaptarlos a tu audiencia y maximizar tus ganancias.
          </p>
        </div>

        {/* Estadísticas y Seguimiento */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Estadísticas y Seguimiento</h2>
          <p className="mb-4">
            <strong>Panel de Afiliados:</strong> Accede a un panel personalizado donde podrás realizar un seguimiento detallado de tus conversiones, comisiones y rendimiento general.
          </p>
          <p>
            <strong>Informes Periódicos:</strong> Recibe informes periódicos que te mantendrán informado sobre tu desempeño y te ayudarán a optimizar tus estrategias de promoción.
          </p>
        </div>

        {/* Soporte y Comunicación */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Soporte y Comunicación</h2>
          <p className="mb-4">
            <strong>Soporte Dedicado:</strong> Contamos con un equipo de soporte dedicado. Ponte en contacto con nosotros a través de correo electrónico o chat en vivo para obtener asistencia personalizada.
          </p>
          <p>
            <strong>Actualizaciones Regulares:</strong> Mantente informado sobre cambios en el programa, nuevos productos y promociones especiales a través de nuestras actualizaciones regulares.
          </p>
        </div>

        {/* Términos y Condiciones */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Términos y Condiciones</h2>
          <p>
            <strong>Políticas del Programa:</strong> Consulta nuestros términos y condiciones para obtener información detallada sobre restricciones, normativas y requisitos.
          </p>
        </div>

        {/* Formulario de registro de afiliados */}
        <div>
          <h2 className="text-2xl font-bold mb-4 text-teal-500">Regístrate como Afiliado</h2>
          <form className="max-w-md" onSubmit={handleSubmit}>
            {/* Campos del formulario */}
            <div className="mb-4">
              <label htmlFor="nombre" className="block text-sm font-medium mb-1">
                Nombre
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                className="p-3 w-full bg-gray-800 text-white rounded-md focus:outline-none focus:ring focus:border-teal-300"
                placeholder="Ingresa tu nombre"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Correo Electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="p-3 w-full bg-gray-800 text-white rounded-md focus:outline-none focus:ring focus:border-teal-300"
                placeholder="Ingresa tu correo electrónico"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="metodoPago" className="block text-sm font-medium mb-1">
                Método de Pago Preferido
              </label>
              <select
                id="metodoPago"
                name="metodoPago"
                value={formData.metodoPago}
                onChange={handleChange}
                className="p-3 w-full bg-gray-800 text-white rounded-md focus:outline-none focus:ring focus:border-teal-300"
                required
              >
                <option value="" disabled>
                  Selecciona tu método de pago
                </option>
                <option value="paypal">PayPal</option>
                <option value="transferencia">Transferencia Bancaria</option>
              </select>
            </div>

            {/* Mensajes de éxito o error con animaciones */}
            {formStatus && (
              <div
                className={`mb-4 text-lg text-center ${
                  formStatus.type === 'success' ? 'text-green-500 animate__animated animate__fadeIn' : 'text-red-500 animate__animated animate__shakeX'
                }`}
              >
                {formStatus.type === 'success' ? (
                  <span className="flex items-center">
                    <FaCheckCircle className="mr-2" />
                    {formStatus.message}
                  </span>
                ) : (
                  <span className="flex items-center">
                    <FaExclamationCircle className="mr-2" />
                    {formStatus.message}
                  </span>
                )}
              </div>
            )}

            {/* Botón de registro con animación */}
            <button
              type="submit"
              className="bg-teal-500 text-white p-3 rounded-md hover:bg-teal-600 transition duration-300 w-full transform hover:scale-105"
            >
              Registrarse como Afiliado
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AffiliatesPage;
