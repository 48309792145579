// Importar las bibliotecas necesarias
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faCalendar, faLock, faStar } from '@fortawesome/free-solid-svg-icons';

// Componente de formulario
const FormField = ({ label, icon, type, id, name, value, onChange, error, required }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {label}
    </label>
    <input
      type={type}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={`mt-1 p-2 border rounded-md w-full ${error ? 'border-red-500' : ''} bg-gray-700 text-gray-300`}
      required={required}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

// Componente principal de Registro
const Registro = () => {
  const [formData, setFormData] = useState({
    userType: 'user',
    full_name: '',
    username: '',
    email: '',
    phone_number: '',
    date_of_birth: '',
    password: '',
    additionalData: '',
    yearsOfExperience: '',
    invitationCode: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const validateForm = () => {
    const errors = {};

    if (!formData.full_name.trim()) {
      errors.full_name = 'Nombre completo es obligatorio';
    }

    if (!formData.username.trim()) {
      errors.username = 'Nombre de usuario es obligatorio';
    } else {
      // Validar que el nombre de usuario no se pueda volver a crear
      if (formData.username.toLowerCase() === 'admin') {
        errors.username = 'Este nombre de usuario ya está en uso';
      }
    }

    if (!formData.email.trim()) {
      errors.email = 'Correo electrónico es obligatorio';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Correo electrónico no válido';
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = 'Teléfono es obligatorio';
    } else if (!/^\d+$/.test(formData.phone_number)) {
      errors.phone_number = 'Número de teléfono no válido (solo se permiten números)';
    }

    if (!formData.date_of_birth.trim()) {
      errors.date_of_birth = 'Fecha de nacimiento es obligatoria';
    } else {
      const birthDate = new Date(formData.date_of_birth);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      if (age < 18) {
        errors.date_of_birth = 'Debes tener al menos 18 años para registrarte';
      }
    }

    if (!formData.password.trim()) {
      errors.password = 'Contraseña es obligatoria';
    } else if (!/(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{6,}/.test(formData.password)) {
      errors.password = 'La contraseña debe tener al menos 6 caracteres, una mayúscula y un símbolo';
    }

    if (formData.userType === 'trader' && !formData.yearsOfExperience.trim()) {
      errors.yearsOfExperience = 'Años de experiencia es obligatorio para los traders';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch('http://localhost:8000/users/api/v1/custom-users/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          // Reiniciar el formulario y mostrar mensaje de éxito
          setFormData({
            userType: 'user',
            full_name: '',
            username: '',
            email: '',
            phone_number: '',
            date_of_birth: '',
            password: '',
            additionalData: '',
            yearsOfExperience: '',
            invitationCode: '',
          });
          setFormErrors({});
          setSuccessMessage('¡Registro exitoso!');
        } else {
          const data = await response.json();
          console.error('Error al enviar los datos del formulario:', data);
        }
      } catch (error) {
        console.error('Error al enviar los datos del formulario:', error.message);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-900 p-2 rounded-md shadow-md max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6">Registro</h1>
        {successMessage && (
          <div className="mb-4 bg-green-600 border border-green-700 text-white p-2 rounded">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="userType" className="block text-sm font-medium">
              Tipo de usuario
            </label>
            <select
              id="userType"
              name="userType"
              value={formData.userType}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-full bg-gray-700 text-gray-300"
            >
              <option value="user">Usuario</option>
              <option value="trader">Trader</option>
            </select>
          </div>
          <FormField
            label="Nombre completo"
            icon={faUser}
            type="text"
            id="full_name"
            name="full_name"
            value={formData.full_name}
            onChange={handleInputChange}
            error={formErrors.full_name || ''}
            required
          />
          <FormField
            label="Nombre de usuario"
            icon={faUser}
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            error={formErrors.username || ''}
            required
          />
          <FormField
            label="Correo electrónico"
            icon={faEnvelope}
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            error={formErrors.email || ''}
            required
          />
          <FormField
            label="Teléfono"
            icon={faPhone}
            type="tel"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleInputChange}
            error={formErrors.phone_number || ''}
            required
          />
          <FormField
            label="Fecha de nacimiento"
            icon={faCalendar}
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleInputChange}
            error={formErrors.date_of_birth || ''}
            required
          />
          <FormField
            label="Contraseña"
            icon={faLock}
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            error={formErrors.password || ''}
            required
          />
          {formData.userType === 'trader' && (
            <FormField
              label="Años de experiencia"
              icon={faStar}
              type="number"
              id="yearsOfExperience"
              name="yearsOfExperience"
              value={formData.yearsOfExperience}
              onChange={handleInputChange}
              error={formErrors.yearsOfExperience || ''}
              required
            />
          )}
          <div className="mb-4">
            <label htmlFor="additionalData" className="block text-sm font-medium">
              Información adicional
            </label>
            <textarea
              id="additionalData"
              name="additionalData"
              value={formData.additionalData}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-full bg-gray-700 text-gray-300"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="invitationCode" className="block text-sm font-medium">
              Código de invitación (opcional)
            </label>
            <input
              type="text"
              id="invitationCode"
              name="invitationCode"
              value={formData.invitationCode}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-full bg-gray-700 text-gray-300"
            />
          </div>
          <button
            type="submit"
            className="bg-teal-500 text-black px-4 py-2 rounded-md hover:bg-teal-600 focus:outline-none focus:border-teal-700 focus:ring focus:ring-blue-200"
          >
            Registrarse
          </button>
        </form>
      </div>
    </div>
  );
};

export default Registro;
