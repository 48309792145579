import React from 'react';
import { Link } from 'react-router-dom';
import Vechain from '../Images/Vechain.png'; // Ajusta la ruta según la estructura de tu proyecto
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const VeChainCoin = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="text-center">
        <img
          src={Vechain}
          alt="VET Coin"
          className="w-48 md:w-64 lg:w-72 mx-auto mb-6 rounded-full shadow-md"
        />
        <p className="text-lg mb-6">
          La moneda VET se utiliza en toda la plataforma.
        </p>
        <Link
          to="/VetPage" // Ajusta la ruta según la configuración de tu React Router
          className="inline-flex items-center text-teal-400 hover:text-pink-300 transition duration-300"
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          Más información sobre Vet
        </Link>
      </div>
    </div>
  );
};

export default VeChainCoin;
