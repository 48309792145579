import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <h1 className="text-4xl font-bold mb-6 text-teal-500">Política de Privacidad</h1>

      <p className="mb-4">
        <em>Última actualización: [Fecha]</em>
      </p>

      <p>
        Bienvenido a [Nombre de tu Empresa]. Esta Política de Privacidad describe cómo manejamos la información personal de nuestros usuarios. Al utilizar nuestros servicios, aceptas las prácticas descritas en esta política.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">1. Información que Recopilamos</h2>

      <p className="mb-4">
        <strong>1.1 Información Proporcionada por el Usuario:</strong> Recopilamos información que nos proporcionas voluntariamente al registrarte, completar formularios, participar en encuestas o interactuar con nuestras redes sociales. Esto puede incluir tu nombre, dirección de correo electrónico, información de contacto, información de facturación y preferencias de comunicación.
      </p>

      <p>
        <strong>1.2 Información Recopilada Automáticamente:</strong> Al utilizar nuestros servicios, recopilamos automáticamente información, como la dirección IP, tipo de navegador, información del dispositivo y páginas visitadas. Esta información nos ayuda a mejorar tu experiencia y analizar el uso de nuestros servicios.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">2. Uso de la Información</h2>

      <p className="mb-4">
        Utilizamos la información recopilada para proporcionar y mejorar nuestros servicios, procesar transacciones, enviar correos electrónicos informativos y promocionales, y cumplir con nuestras obligaciones legales.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">3. Compartir Información</h2>

      <p>
        <strong>No vendemos tu información personal:</strong> No compartimos, vendemos ni alquilamos tu información personal a terceros sin tu consentimiento, excepto cuando sea necesario para proporcionar nuestros servicios o cumplir con la ley.
      </p>

      <p>
        <strong>Proveedores de Servicios:</strong> Podemos compartir información con proveedores de servicios que nos ayudan con el procesamiento de pagos, marketing y análisis. Estos proveedores están obligados a proteger tu información.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">4. Cookies y Tecnologías Similares</h2>

      <p className="mb-4">
        Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario y analizar el uso de nuestros servicios. Puedes ajustar la configuración de cookies en tu navegador, pero ten en cuenta que esto puede afectar la funcionalidad de nuestros servicios.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">5. Seguridad</h2>

      <p className="mb-4">
        Implementamos medidas razonables para proteger la información personal. Sin embargo, ten en cuenta que ninguna transmisión de datos por Internet o almacenamiento electrónico es completamente segura.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">6. Tus Derechos</h2>

      <p className="mb-4">
        Tienes derechos sobre tu información personal. Puedes solicitar acceso, corrección, eliminación u oposición al procesamiento de tus datos personales. Contáctanos para ejercer tus derechos.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">7. Cambios en la Política de Privacidad</h2>

      <p className="mb-4">
        Nos reservamos el derecho de actualizar esta Política de Privacidad. La fecha de la última actualización se indicará al principio de la política. Te recomendamos revisar periódicamente la política para conocer cualquier cambio.
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">8. Contacto</h2>

      <p className="mb-4">
        Para cualquier pregunta, comentario o solicitud relacionada con esta Política de Privacidad, no dudes en ponerte en contacto con nosotros a través de [correo electrónico de contacto].
      </p>

      <h2 className="text-2xl font-bold my-4 text-teal-500">9. Consentimiento</h2>

      <p>
        Al utilizar nuestros servicios, aceptas los términos de esta Política de Privacidad.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
