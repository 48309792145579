import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const faqData = [
  {
    question: '¿Cómo puedo empezar a invertir en SVFinancex?',
    answer: 'Para comenzar a invertir con SVFinancex, crea una cuenta, selecciona el tipo de inversión y contacta con uno de nuestros brokers para recibir asesoramiento personalizado.',
    additionalInfo: 'Consulta nuestra sección de "Guía para Principiantes" para obtener más información.',
  },
  {
    question: '¿Cuáles son los requisitos para convertirme en un broker certificado?',
    answer: 'Para convertirte en un broker certificado en SVFinancex, debes tener un historial probado de rentabilidad en tus transacciones diarias. Contáctanos para más detalles y para iniciar el proceso de certificación.',
    additionalInfo: 'Descubre más sobre el proceso de certificación en nuestra sección de "Convertirse en Broker".',
  },
  {
    question: '¿Cómo garantizan la seguridad de mis inversiones?',
    answer: 'En SVFinance, priorizamos la seguridad de las inversiones. Utilizamos tecnología avanzada de seguridad P2P para todas las transacciones usuario/broker y aseguramos que tus datos personales y bancarios estén protegidos.',
    additionalInfo: 'Lee más sobre nuestras medidas de seguridad en la sección de "Seguridad de Inversiones".',
  },
  {
    question: '¿Cuánto tiempo lleva el proceso de certificación para brokers?',
    answer: 'El tiempo de certificación para brokers puede variar, pero generalmente involucra una revisión exhaustiva de tu historial y desempeño. Una vez que presentas tu solicitud, nuestro equipo revisará la información y se pondrá en contacto contigo.',
    additionalInfo: 'Infórmate sobre los tiempos de certificación en la sección "Proceso de Certificación".',
  },
  {
    question: '¿Puedo invertir en múltiples tipos de activos a la vez?',
    answer: 'Sí, en SVFinancex puedes diversificar tu cartera de inversiones invirtiendo en múltiples tipos de activos, como acciones, bonos, bienes raíces y más. Nuestros brokers pueden ayudarte a crear una estrategia de inversión personalizada.',
    additionalInfo: 'Descubre más sobre la diversificación en la sección "Diversificación de Inversiones".',
  },
  // Preguntas y respuestas adicionales
  {
    question: '¿Cómo puedo contactar al soporte técnico?',
    answer: 'Puedes contactar a nuestro equipo de soporte técnico a través del formulario de contacto en la sección "Soporte Técnico" o enviando un correo electrónico a support@svfinancex.com.',
    additionalInfo: 'Estamos disponibles las 24 horas del día, los 7 días de la semana para ayudarte.',
  },
  {
    question: '¿Ofrecen seminarios en línea sobre estrategias de inversión?',
    answer: 'Sí, organizamos seminarios en línea gratuitos sobre diversas estrategias de inversión. Consulta nuestro calendario de eventos en la sección "Seminarios" para conocer las fechas y temas.',
    additionalInfo: 'Regístrate para recibir notificaciones sobre nuestros próximos seminarios.',
  },
  {
    question: '¿Cómo puedo retirar mis ganancias?',
    answer: 'Puedes retirar tus ganancias en la sección "Retiros" de tu cuenta. Aceptamos diversas formas de retiro, como transferencias bancarias y monederos electrónicos.',
    additionalInfo: 'Consulta nuestras políticas de retiro para obtener más detalles sobre los tiempos y límites.',
  },
  {
    question: '¿Tienen alguna aplicación móvil?',
    answer: 'Sí, ofrecemos una aplicación móvil para facilitar el acceso a tu cuenta y realizar inversiones desde tu dispositivo móvil. Puedes descargarla desde la tienda de aplicaciones de tu dispositivo.',
    additionalInfo: 'La aplicación estará disponible para dispositivos iOS y Android.',
  },
  {
    question: '¿Cómo puedo participar en programas de referidos?',
    answer: 'Puedes participar en nuestros programas de referidos invitando a amigos a unirse a SVFinancex. Encuentra tu enlace de referido en la sección "Referidos" y compártelo con tus amigos. Obtén recompensas por cada amigo que se registre y realice una inversión.',
    additionalInfo: 'Más referidos significan más recompensas. ¡Únete a nuestro programa de referidos ahora!',
  },
  // Nuevas secciones y preguntas
  {
    question: '¿Cómo funciona SVFinancex?',
    answer: 'SVFinancex conecta a inversores con traders y brokers certificados. Los inversores pueden seleccionar el tipo de inversión y recibir asesoramiento de expertos para maximizar sus ganancias.',
    additionalInfo: 'Explora la sección "Cómo Funciona" para obtener una guía detallada del proceso.',
  },
  {
    question: '¿Cuáles son las políticas de privacidad y términos de servicio?',
    answer: 'Nuestras políticas de privacidad y términos de servicio están diseñadas para proteger tu información y establecer las reglas y responsabilidades al usar nuestros servicios. Consulta la sección "Políticas y Términos" para más detalles.',
    additionalInfo: 'Es importante que leas y comprendas nuestras políticas antes de utilizar nuestros servicios.',
  },
];
// ... (importaciones y otras partes del código)

// ... (importaciones y otras partes del código)

const FAQItem = ({ index, question, answer, additionalInfo, isActive, onClick }) => {
  const springProps = useSpring({
    height: isActive ? 'auto' : 0,
    opacity: isActive ? 1 : 0,
    marginTop: isActive ? '1rem' : '0',
  });

  return (
    <div className="mb-6">
      <div
        className={`cursor-pointer flex justify-between items-center p-4 bg-gray-900 rounded-lg ${
          isActive ? 'rounded-b-none' : ''
        }`}
        onClick={() => onClick(index)}
      >
        <div className={`text-lg font-bold text-teal-500 ${isActive ? 'underline' : ''}`}>
          {question}
        </div>
        <div className={`text-teal-500 ${isActive ? 'transform rotate-45' : ''}`}>+</div>
      </div>
      <animated.div style={springProps} className="overflow-hidden transition-all">
        <div className="p-4 bg-gray-800 rounded-b-lg text-white">
          <p className="text-lg mb-4">{answer}</p>
          <p className="text-sm text-gray-500">{additionalInfo}</p>
        </div>
      </animated.div>
    </div>
  );
};

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const closeAllSections = () => {
    setActiveIndex(null);
  };

  const renderFAQItems = () => {
    return faqData.map((item, index) => (
      <FAQItem
        key={index}
        index={index}
        question={item.question}
        answer={item.answer}
        additionalInfo={item.additionalInfo}
        isActive={activeIndex === index}
        onClick={toggleAccordion}
      />
    ));
  };

  return (
    <div className="min-h-screen bg-cover bg-gray-900 p-8 rounded-lg text-center md:text-left">
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 text-white">Preguntas Frecuentes</h2>
      {renderFAQItems()}
    </div>
  );
};

export default FAQ;
