import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDoubleRightIcon, BadgeCheckIcon, UserCircleIcon } from '@heroicons/react/solid';

const traders = [
  { id: 1, name: 'Smith', profit: '$258', totalTrades: 1.148, location: 'London', followers: 1200, bio: 'Expert in cryptocurrency trading strategies.', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
  { id: 2, name: 'Anah', profit: '$1.203', totalTrades: 2.511, location: 'London', followers: 950, bio: 'Specializes in forex trading and technical analysis.', avatar: 'https://randomuser.me/api/portraits/women/2.jpg' },
  { id: 3, name: 'Fede', profit: '$144', totalTrades: 11.549, location: 'Tokyo', followers: 800, bio: 'Futures market expert with over 10 years of experience.', avatar: 'https://randomuser.me/api/portraits/men/3.jpg' },
  { id: 4, name: 'Eloise', profit: '$156', totalTrades: 1.351, location: 'Hong Kong', followers: 1500, bio: 'Passionate about commodities and stock trading.', avatar: 'https://randomuser.me/api/portraits/women/4.jpg' },
  { id: 5, name: 'Jhon', profit: '$991', totalTrades: 623, location: 'USA', followers: 1100, bio: 'Options trading specialist with a focus on risk management.', avatar: 'https://randomuser.me/api/portraits/men/5.jpg' },
  { id: 6, name: 'Monica', profit: '$1.331', totalTrades: 284, location: 'Spain', followers: 700, bio: 'Algorithmic trading enthusiast and AI researcher.', avatar: 'https://randomuser.me/api/portraits/women/6.jpg' },
];

const TraderCard = ({ trader, index, openTraderDetails }) => {
  let badgeColor = 'text-gray-500';
  if (index === 0) badgeColor = 'text-yellow-500'; // Oro
  else if (index === 1) badgeColor = 'text-gray-500'; // Plata
  else if (index === 2) badgeColor = 'text-yellow-900'; // Bronce

  return (
    <div
      className="relative bg-gradient-to-br from-teal-600 to-pink-500 shadow-md rounded-lg overflow-hidden cursor-pointer hover:shadow-lg transition duration-300"
      onClick={() => openTraderDetails(trader)}
    >
      <div className="p-4">
        <div className="flex items-center mb-4">
          <div className="w-12 h-12 rounded-full overflow-hidden">
            <img className="w-full h-full object-cover" src={trader.avatar} alt={`${trader.name}'s avatar`} />
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">{trader.name}</h3>
            <p className="text-gray-400 text-sm">{trader.location}</p>
          </div>
        </div>
        <div className="flex items-center mb-2">
          <BadgeCheckIcon className={`w-6 h-6 ${badgeColor} mr-1`} />
          <div className="text-lg font-semibold">{trader.profit}</div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-gray-400">Total Trades: {trader.totalTrades}</div>
          <button
            className="flex items-center bg-teal-300 hover:bg-teal-400 text-black py-2 px-4 rounded-lg focus:outline-none"
          >
            Ver Detalles <ChevronDoubleRightIcon className="w-5 h-5 ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

const TopTraders = () => {
  const [selectedTrader, setSelectedTrader] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openTraderDetails = (trader) => {
    setSelectedTrader(trader);
    setIsOpen(true);
  };

  const closeTraderDetails = () => {
    setSelectedTrader(null);
    setIsOpen(false);
  };

  // Ordenar traders por profit de forma descendente
  const sortedTraders = [...traders].sort((a, b) => parseFloat(b.profit.replace(',', '').slice(1)) - parseFloat(a.profit.replace(',', '').slice(1)));

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold text-center mb-8">SVFinanceX Top Traders</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {sortedTraders.map((trader, index) => (
            <TraderCard
              key={trader.id}
              trader={trader}
              index={index}
              openTraderDetails={openTraderDetails}
            />
          ))}
        </div>
      </div>

      {/* Modal de Detalles */}
      <Transition
        show={isOpen}
        as={React.Fragment}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 w-80">
            <h2 className="text-2xl font-semibold mb-4">{selectedTrader?.name}</h2>
            <div className="flex items-center mb-2">
              <UserCircleIcon className="w-8 h-8 text-gray-600 mr-2" />
              <p className="text-lg text-gray-700">{selectedTrader?.location}</p>
            </div>
            <p className="text-lg text-gray-700">Profit: {selectedTrader?.profit}</p>
            <p className="text-lg text-gray-700">Total Trades: {selectedTrader?.totalTrades}</p>
            <p className="text-sm text-gray-500 mt-2">{selectedTrader?.bio}</p>
            <p className="text-sm text-gray-500">Followers: {selectedTrader?.followers}</p>
            <button
              className="mt-4 bg-teal-300 hover:bg-teal-400 text-white py-2 px-4 rounded-lg focus:outline-none"
              onClick={closeTraderDetails}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default TopTraders;
