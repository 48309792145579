import React, { useState, useEffect } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { AiOutlineSend } from 'react-icons/ai';
import { BiChevronLeft, BiX, BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { useSpring, animated } from 'react-spring';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [selectedSection, setSelectedSection] = useState('main');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [previousSection, setPreviousSection] = useState(null);
  const [showSections, setShowSections] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const responses = {
    faqs: [
      { question: '¿Qué es un pool?', answer: 'Los pools son piscinas de liquidez en la que los tarders generan ganancias y las reparten equitativamente.' },
      { question: '¿Cuáles son los horarios de atención?', answer: 'Estamos disponibles de lunes a viernes de 9:00 a 18:00.' },
      { question: '¿Ofrecen soporte técnico?', answer: 'Sí, ofrecemos soporte técnico 24/7.' }
    ],
    polls: [
      { question: '¿Le gustaría participar en una encuesta sobre nuestros servicios?', answer: '' },
      { question: '¿Qué tan satisfecho está con nuestra plataforma? Califique del 1 al 5.', answer: '' },
      { question: '¿Qué mejoras le gustaría ver en nuestro servicio?', answer: '' }
    ],
    realEstate: [
      { question: '¿Qué tipo de propiedad le interesa?', answer: 'Ej: Casa, Apartamento, Oficina.' },
      { question: '¿Cuál es su presupuesto aproximado?', answer: '' },
      { question: '¿En qué ubicación está buscando la propiedad?', answer: '' },
      { question: '¿Cuál es el tamaño ideal de la propiedad?', answer: '' },
      { question: '¿Necesita alguna característica específica, como piscina o jardín?', answer: '' }
    ],
    trading: [
      { question: '¿Está interesado en la intermediación de traders?', answer: 'Ofrecemos servicios de ...' },
      { question: '¿Cuáles son sus objetivos de trading?', answer: 'Ej: Rentabilidad a corto plazo, inversión a largo plazo, etc.' },
      { question: '¿Qué tipo de traders le gustaría conocer?', answer: 'Ej: Traders de acciones, cripto traders, etc.' }
    ],
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setMessages([{ text: '¡Hola! ¿En qué puedo ayudarte hoy? Elige una opción o escribe tu pregunta.', sender: 'bot' }]);
    }
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };
      setMessages([...messages, newMessage]);
      setInput('');

      if (selectedSection === 'main') {
        handleMainSectionInput(input.toLowerCase());
      } else if (Array.isArray(responses[selectedSection])) {
        handleQuestionAnswer();
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Opción no válida. Elija una sección o escriba su pregunta.', sender: 'bot' }
        ]);
      }
    }
  };

  const handleMainSectionInput = (input) => {
    if (input.includes('preguntas frecuentes')) {
      setSelectedSection('faqs');
      setCurrentQuestionIndex(0);
      setMessages([{ text: responses.faqs[0].question, sender: 'bot' }]);
    } else if (input.includes('encuestas')) {
      setSelectedSection('polls');
      setCurrentQuestionIndex(0);
      setMessages([{ text: responses.polls[0].question, sender: 'bot' }]);
    } else if (input.includes('bienes raíces')) {
      setSelectedSection('realEstate');
      setCurrentQuestionIndex(0);
      setMessages([{ text: responses.realEstate[0].question, sender: 'bot' }]);
    } else if (input.includes('intermediación de traders')) {
      setSelectedSection('trading');
      setCurrentQuestionIndex(0);
      setMessages([{ text: responses.trading[0].question, sender: 'bot' }]);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: 'Por favor, elija una opción válida o escriba su pregunta.', sender: 'bot' }
      ]);
    }
  };

  const handleQuestionAnswer = () => {
    const questions = responses[selectedSection];
    if (currentQuestionIndex < questions.length) {
      const currentQuestion = questions[currentQuestionIndex];
      if (input.toLowerCase()) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: currentQuestion.answer, sender: 'bot' }
        ]);
        setWaitingForResponse(false);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setInput('');
        if (currentQuestionIndex + 1 < questions.length) {
          const nextQuestion = questions[currentQuestionIndex + 1].question;
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: nextQuestion, sender: 'bot' }
          ]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Gracias por tu tiempo. Si tienes más preguntas, por favor escríbelas.', sender: 'bot' }
          ]);
          setSelectedSection('main');
          setCurrentQuestionIndex(0);
        }
      } else {
        setWaitingForResponse(true);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSectionChange = (section) => {
    setPreviousSection(selectedSection);
    setSelectedSection(section);
    setCurrentQuestionIndex(0);
    setMessages([{ text: responses[section][0].question, sender: 'bot' }]);
    setShowSections(false);
  };

  const handleBack = () => {
    if (previousSection) {
      setSelectedSection(previousSection);
      setPreviousSection(null);
      setMessages([{ text: 'Regresaste al menú principal.', sender: 'bot' }]);
      setCurrentQuestionIndex(0);
    }
  };

  const animationProps = useSpring({
    transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
    opacity: isOpen ? 1 : 0,
    config: { tension: 250, friction: 30 }
  });

  useEffect(() => {
    if (waitingForResponse) {
      const timer = setTimeout(() => {
        handleQuestionAnswer();
      }, 3000); // Avanzar a la siguiente pregunta después de 3 segundos si no hay respuesta

      return () => clearTimeout(timer);
    }
  }, [waitingForResponse]);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-500 focus:outline-none"
        onClick={toggleChat}
      >
        <FiMessageCircle size={24} />
      </button>
      <animated.div
        style={animationProps}
        className="bg-white w-80 h-96 rounded-lg shadow-lg p-4 absolute bottom-16 right-0 flex flex-col"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Chatbot</h2>
          <button
            className="text-gray-500 hover:text-gray-800 focus:outline-none"
            onClick={toggleChat}
          >
            <BiX size={24} />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto mb-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-2 p-2 rounded-lg ${
                message.sender === 'user'
                  ? 'bg-blue-600 text-white self-end'
                  : 'bg-gray-200 text-gray-800 self-start'
              }`}
            >
              {message.text}
            </div>
          ))}
        </div>
        {selectedSection === 'main' && (
          <div className="flex flex-col mb-4 items-center">
            <button
              onClick={() => setShowSections(!showSections)}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300 mb-2 flex items-center"
            >
              <span className="mr-2">Opciones</span>
              {showSections ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
            </button>
            {showSections && (
              <div className="flex flex-col items-center">
                <button
                  onClick={() => handleSectionChange('faqs')}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 mb-2"
                >
                  Preguntas Frecuentes
                </button>
                <button
                  onClick={() => handleSectionChange('polls')}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 mb-2"
                >
                  Encuestas
                </button>
                <button
                  onClick={() => handleSectionChange('realEstate')}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 mb-2"
                >
                  Bienes Raíces
                </button>
                <button
                  onClick={() => handleSectionChange('trading')}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 mb-2"
                >
                  Intermediación de Traders
                </button>
              </div>
            )}
          </div>
        )}
        {selectedSection !== 'main' && (
          <button
            onClick={handleBack}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 mt-4 self-center flex items-center"
          >
            <BiChevronLeft size={16} className="mr-2" /> Volver
          </button>
        )}
        <div className="flex items-center mt-4">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            className="flex-1 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
            placeholder="Escribe un mensaje..."
          />
          <button
            className="ml-2 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-500 focus:outline-none"
            onClick={handleSendMessage}
          >
            <AiOutlineSend size={20} />
          </button>
        </div>
      </animated.div>
    </div>
  );
};

export default ChatBot;
