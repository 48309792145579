import React, { useState, useEffect } from 'react';

const PropertyList = () => {
  const [investorsCount, setInvestorsCount] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('default');

  const properties = [
    {
      id: 1,
      title: "Chalet Independiente",
      description: "CHALET DE DISEÑO MODERNO CON PISCINA Y AMPLIA PARCELA JUNTO AL MAR.",
      image: "https://static.vecteezy.com/system/resources/previews/008/132/055/non_2x/realistic-house-drawing-illustration-vector.jpg",
      price: 1450000,
      location: "Torrevieja/Alicante",
      investorsNeeded: 13500,
      time: "Medio plazo",
      offerLink: "https://www.idealista.com/inmueble/103177845/?xtmc=1_1_hemingway&xtcr=0",
      paymentLink: "https://commerce.coinbase.com/checkout/21443f58-5819-4a36-991f-f3841768b594"
    },
    {
      id: 2,
      title: "Piso Moderno en el Centro de la Ciudad",
      description: "Un piso moderno y luminoso en una ubicación céntrica, cerca de todas las comodidades.",
      image: "https://static.vecteezy.com/system/resources/previews/026/536/120/original/one-continuous-line-drawing-of-house-concept-doodle-illustration-in-simple-linear-style-vector.jpg",
      price: 115000,
      location: "Carretera del Centro, Las Palmas de Gran Canaria",
      investorsNeeded: 5000,
      time: "Medio plazo",
      offerLink: "https://www.idealista.com/inmueble/104352849/?xtmc=1_1_hemingway&xtcr=0",
      paymentLink: "https://commerce.coinbase.com/checkout/fb33e5dc-ffb6-4565-867a-975f117f9321"
    },
    {
      id: 3,
      title: "Chalet pareado en venta en Canillas",
      description: "Chalet pareado familiar con una distribución excepcional. Al ingresar a la propiedad, se encuentra una cocina abierta con zona de office, conectada de manera fluida al espacioso salón, creando un ambiente perfecto para el entretenimiento y la vida familiar.",
      image: "https://static.vecteezy.com/system/resources/previews/026/536/116/non_2x/one-continuous-line-drawing-of-house-concept-doodle-illustration-in-simple-linear-style-vector.jpg",
      price: 2050000,
      location: "Hortaleza/Madrid",
      investorsNeeded: 13000,
      time: "Medio plazo",
      offerLink: "https://www.idealista.com/inmueble/104079750/",
      paymentLink: "https://commerce.coinbase.com/checkout/46a5d7d8-f330-48b7-8479-6221623c0b64"
    },
    {
      id: 4,
      title: "Local en venta en calle de la Vega, 486",
      description: "Ubicado en una de las principales vías comerciales de la ciudad con muy alto tránsito de Vehículos y peatones.",
      image: "https://static.vecteezy.com/system/resources/previews/026/536/116/non_2x/one-continuous-line-drawing-of-house-concept-doodle-illustration-in-simple-linear-style-vector.jpg",
      price: 150000,
      location: "La Florida, Alicante / Alacant",
      investorsNeeded: 10000,
      time: "Medio plazo",
      offerLink: "https://www.idealista.com/inmueble/94171569/?xtmc=1_6_licencia-taxi&xtcr=0",
      paymentLink: "https://commerce.coinbase.com/checkout/140d8366-eb6d-48fe-9346-23981ac81c1e"
    },
    {
      id: 5,
      title: "Local en venta en calle de Sant Mateu",
      description: "Local en venta en zona muy transitada y a pocos metros de la calle con más afluencia de comercios del barrio de Carolinas Altas.",
      image: "https://static.vecteezy.com/system/resources/previews/026/536/090/non_2x/one-continuous-line-drawing-of-house-concept-doodle-illustration-in-simple-linear-style-vector.jpg",
      price: 100000,
      location: "Carolinas Altas, Alicante / Alacant",
      investorsNeeded: 3600,
      time: "Medio plazo",
      offerLink: "https://www.idealista.com/inmueble/103501507/",
      paymentLink: "https://commerce.coinbase.com/checkout/65ff271a-a3d9-427f-8574-22e081ddfe99"
    },
    {
      id: 6,
      title: "Local en venta Alicante",
      description: "Con 3 espaciosas estancias, este local es perfecto para una amplia variedad de negocios. La propiedad ha sido reformada para brindarte un espacio moderno y atractivo para tus clientes.",
      image: "https://static.vecteezy.com/system/resources/previews/008/132/055/non_2x/realistic-house-drawing-illustration-vector.jpg",
      price: 750000,
      location: "Nou Alacant, Alicante / Alacant",
      investorsNeeded: 5000,
      time: "Corto plazo",
      offerLink: "https://www.idealista.com/inmueble/103104134/",
      paymentLink: "https://commerce.coinbase.com/checkout/6eaa9680-6886-4ede-9b3d-204e0c5913bb"
    }
  ];

  // Función para inicializar inversores
  const initializeInvestors = () => {
    const initialCounts = {};
    properties.forEach(property => {
      initialCounts[property.id] = Math.floor(Math.random() * 90) + 10; // Entre 10 y 100 inversores inicialmente
    });
    setInvestorsCount(initialCounts);
  };

  // Inicialización de inversores al cargar el componente
  useEffect(() => {
    initializeInvestors();
  }, []);

  // Simulación de incremento de inversores cada 24 horas
  useEffect(() => {
    const intervalId = setInterval(() => {
      setInvestorsCount(prevCounts => {
        const newCounts = { ...prevCounts };
        properties.forEach(property => {
          newCounts[property.id] = newCounts[property.id] + Math.floor(Math.random() * 10) + 1;
        });
        return newCounts;
      });
    }, 24 * 60 * 60 * 1000); // 24 horas en milisegundos

    return () => clearInterval(intervalId);
  }, [properties]);

  const getPropertyPopularityIndicator = (property) => {
    const investorCount = investorsCount[property.id] || 0;
    const percentage = (investorCount / property.investorsNeeded) * 100;
    if (percentage === 0) {
      return "No hay inversores";
    } else if (percentage <= 20) {
      return "Poco popular";
    } else if (percentage <= 60) {
      return "Moderadamente popular";
    } else {
      return "Muy popular";
    }
  };

  const calculatePropertyROI = (property) => {
    const investorCount = investorsCount[property.id] || 0;
    const roi = ((investorCount / property.investorsNeeded) * 4 + 1).toFixed(2); // Rentabilidad entre 1% y 5%
    return roi;
  };

  const getPropertyROIColor = (roi) => {
    if (roi >= 4) {
      return "text-green-500";
    } else if (roi >= 2) {
      return "text-yellow-500";
    } else {
      return "text-gray-400";
    }
  };

  const filteredProperties = properties
    .filter(property =>
      property.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.location.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOption === 'priceLowToHigh') {
        return a.price - b.price;
      } else if (sortOption === 'priceHighToLow') {
        return b.price - a.price;
      } else if (sortOption === 'roiHighToLow') {
        return calculatePropertyROI(b) - calculatePropertyROI(a);
      } else if (sortOption === 'popularityHighToLow') {
        const popularityA = getPropertyPopularityIndicator(a);
        const popularityB = getPropertyPopularityIndicator(b);
        if (popularityA === popularityB) {
          return 0;
        } else if (popularityA === "Muy popular") {
          return -1;
        } else if (popularityB === "Muy popular") {
          return 1;
        } else if (popularityA === "Moderadamente popular") {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    });

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-center">Propiedades para Invertir</h1>
        <div className="mb-6 flex justify-between">
          <input
            type="text"
            placeholder="Buscar por título o ubicación"
            className="w-full md:w-1/3 p-2 rounded bg-gray-800 text-white"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="w-full md:w-1/3 p-2 rounded bg-gray-800 text-white ml-4"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="default">Ordenar por</option>
            <option value="priceLowToHigh">Precio: Bajo a Alto</option>
            <option value="priceHighToLow">Precio: Alto a Bajo</option>
            <option value="roiHighToLow">Rentabilidad: Alta a Baja</option>
            <option value="popularityHighToLow">Popularidad: Alta a Baja</option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProperties.map((property) => (
            <div key={property.id} className={`rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 ${getPropertyPopularityIndicator(property) === "Muy popular" ? "border-4 border-yellow-500" : ""}`}>
              <img src={property.image} alt={property.title} className="w-full h-64 object-cover" />
              <div className="p-6 bg-gray-800">
                <div className="font-bold text-xl mb-2">{property.title}</div>
                <p className="text-gray-300 mb-4">{property.description}</p>
                <p className="text-gray-400">Ubicación: {property.location}</p>
                <p className="text-gray-400">Precio: ${property.price.toLocaleString()}</p>
                <p className="text-gray-400">Inversores necesarios: {property.investorsNeeded}</p>
                <p className="text-gray-400 mt-2">Inversores actuales: {investorsCount[property.id] || 0}</p>
                <p className="text-gray-400 mt-2">Popularidad: {getPropertyPopularityIndicator(property)}</p>
                <p className={`text-gray-400 mt-2 ${getPropertyROIColor(calculatePropertyROI(property))}`}>
                  Rentabilidad: {calculatePropertyROI(property)}%
                </p>
                <a href={property.offerLink} target="_blank" rel="noopener noreferrer" className="block bg-pink-400 hover:bg-pink-300 text-black font-bold py-2 px-4 rounded mt-4 text-center">
                  Ver oferta
                </a>
                <a href={property.paymentLink} target="_blank" rel="noopener noreferrer" className="block bg-teal-300 hover:bg-teal-400 text- font-bold py-2 px-4 rounded mt-2 text-center">
                  Invertir
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PropertyList;
