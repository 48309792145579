// Home.js
import React from 'react';
import MemecoinPoolsAd from './MemecoinPoolsAd';

const PoolsHome = () => {
  return (
    <div>
      <MemecoinPoolsAd/>
    </div>
  );
};

export default PoolsHome;
