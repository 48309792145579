// Profile.js
import React from 'react';
import Sidebar from './Sidebar'; // Ajusta la ruta de importación según la ubicación de Sidebar.js

const Profile = () => {
  // Supongamos que aquí obtienes la información del perfil del usuario desde algún lugar
  const userProfile = {
    username: 'usuario123',
    fullName: 'Usuario de Ejemplo',
    email: 'usuario123@example.com',
    birthdate: '01 de enero de 1990',
    gender: 'Femenino',
    location: 'Ciudad de Ejemplo',
    // ... otras propiedades del perfil
    bankInfo: {
      accountNumber: '0123456789',
      bankName: 'Banco Ejemplo',
      // ... otras propiedades de la información bancaria
    },
    cryptoInfo: {
      bitcoinAddress: '1AbCdEfGhIjKlMnOpQrStUvWxYz',
      ethereumAddress: '0xAbCdEf1234567890',
      // ... otras propiedades de la información de criptomonedas
    },
  };

  const recentActivity = [
    { action: 'Publicó una foto', date: 'Hace 2 días' },
    { action: 'Comentó en un post', date: 'Hace 3 días' },
    // ... más actividades recientes
  ];

  const editProfile = () => {
    // Lógica para editar el perfil
    console.log('Editar Perfil');
  };

  const changePassword = () => {
    // Lógica para cambiar la contraseña
    console.log('Cambiar Contraseña');
  };

  const deleteAccount = () => {
    // Lógica para eliminar la cuenta
    console.log('Eliminar Cuenta');
  };

  return (
    <div className="flex h-screen bg-gray-900 text-white">
      <Sidebar /> {/* Agrega el Sidebar aquí */}

      <div className="flex-1 p-8">
        <div className="bg-gray-900 text-white p-6 rounded-md">
          <h1 className="text-3xl font-bold mb-4">Perfil del Usuario</h1>

          <div className="flex flex-col md:flex-row">
            {/* Parte Izquierda - Información Básica y Detalles del Perfil */}
            <div className="mb-8 md:mr-8 md:w-1/2">
              <div className="flex items-center mb-4">
                <img
                  src="URL_de_la_foto_de_perfil"
                  alt="Foto de perfil"
                  className="rounded-full w-20 h-20 mr-4"
                />
                <div>
                  <p className="text-xl font-bold">{userProfile.fullName}</p>
                  <p className="text-gray-500">@{userProfile.username}</p>
                </div>
              </div>

              <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">Detalles del Perfil</h2>
                <p className="mb-2"><strong>Email:</strong> {userProfile.email}</p>
                <p className="mb-2"><strong>Fecha de nacimiento:</strong> {userProfile.birthdate}</p>
                <p className="mb-2"><strong>Género:</strong> {userProfile.gender}</p>
                <p className="mb-2"><strong>Ubicación:</strong> {userProfile.location}</p>
                {/* Agrega más información del perfil según sea necesario */}
              </div>
            </div>

            {/* Parte Derecha - Información Bancaria, Criptomonedas y Actividad Reciente */}
            <div className="md:w-1/2">
              {/* Información Bancaria */}
              <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">Información Bancaria</h2>
                <p className="mb-2"><strong>Número de cuenta:</strong> {userProfile.bankInfo.accountNumber}</p>
                <p className="mb-2"><strong>Banco:</strong> {userProfile.bankInfo.bankName}</p>
                {/* Agrega más información bancaria según sea necesario */}
              </div>

              {/* Información de Criptomonedas */}
              <div className="mb-6">
                <h2 className="text-2xl font-bold mb-2">Información de Criptomonedas</h2>
                <p className="mb-2"><strong>Dirección de Bitcoin:</strong> {userProfile.cryptoInfo.bitcoinAddress}</p>
                <p className="mb-2"><strong>Dirección de Ethereum:</strong> {userProfile.cryptoInfo.ethereumAddress}</p>
                {/* Agrega más información de criptomonedas según sea necesario */}
              </div>

              {/* Actividad Reciente */}
              <div>
                <h2 className="text-2xl font-bold mb-4">Actividad Reciente</h2>
                {recentActivity.map((activity, index) => (
                  <div key={index} className="mb-2">
                    <p>{activity.action}</p>
                    <p className="text-gray-500">{activity.date}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Acciones */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              className="bg-teal-500 text-white px-6 py-3 rounded-md hover:bg-teal-700 transition duration-300"
              onClick={editProfile}
            >
              Editar Perfil
            </button>
            <button
              className="bg-gray-500 text-white px-6 py-3 rounded-md hover:bg-gray-700 transition duration-300"
              onClick={changePassword}
            >
              Cambiar Contraseña
            </button>
            <button
              className="bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-700 transition duration-300"
              onClick={deleteAccount}
            >
              Eliminar Cuenta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
