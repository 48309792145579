import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

const CookieNotice = () => {
  const [accepted, setAccepted] = useState(false);

  const acceptCookies = () => {
    setAccepted(true);
  };

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookiesAccepted');
    if (!isAccepted) {
      setAccepted(false);
    }
  }, []);

  useEffect(() => {
    if (accepted) {
      localStorage.setItem('cookiesAccepted', 'true');
    }
  }, [accepted]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      acceptCookies();
    }
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setAccepted(true);
    }
  };

  return (
    <div>
      <CSSTransition
        in={!accepted}
        timeout={300}
        classNames="cookie-notice"
        unmountOnExit
      >
        <div
          className="fixed bottom-4 left-0 right-0 mx-auto bg-gray-900 text-white p-6 rounded-md shadow-md z-50 cursor-pointer max-w-md lg:max-w-xl"
          onClick={handleOutsideClick}
        >
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <p className="text-center lg:text-left mb-4 lg:mb-0">
              Este sitio utiliza cookies para mejorar la experiencia del usuario.{' '}
              <Link to="/PolicyPage" className="text-blue-500 underline ml-2">
                Política de Cookies
              </Link>
            </p>
            <div className="flex items-center mt-4 lg:mt-0">
              <button
                className="bg-teal-500 hover:bg-teal-600 text-white px-4 py-2 rounded focus:outline-none mr-4 transition duration-300"
                onClick={acceptCookies}
                onKeyPress={handleKeyPress}
              >
                Aceptar
              </button>
              <button
                className="text-gray-300 hover:text-white focus:outline-none transition duration-300"
                onClick={() => setAccepted(true)}
                aria-label="Cerrar aviso de cookies"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CookieNotice;
