// Home.js
import React from 'react';
import InvestmentPools from './InvestmentPools';
import PropertyList from './PropertyList';


const TraderHome = () => {
  return (
    <div>
      <PropertyList />
    </div>
  );
};

export default TraderHome;
