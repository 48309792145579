import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

const Nosotros = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-8 text-teal-300">Acerca de SVFinancex</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Nuestra Misión</h2>
          <p>
            En SVFinancex, nos esforzamos por proporcionar servicios de intermediación financiera de alta calidad,
            conectando traders profesionales con usuarios sin experiencia. Buscamos democratizar el acceso a
            oportunidades financieras y facilitar el crecimiento patrimonial de todos nuestros usuarios.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Cómo Funciona</h2>
          <p>
            SVFinancex brinda a los usuarios la oportunidad de beneficiarse de las estrategias probadas de traders
            profesionales. Nuestra plataforma intuitiva facilita la conexión, permitiendo a los usuarios seguir,
            aprender y ganar junto con traders experimentados sin la necesidad de ser expertos financieros.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Valores Fundamentales</h2>
          <ul className="list-disc ml-6">
            <li>Transparencia y honestidad en todas nuestras interacciones.</li>
            <li>Compromiso con la seguridad y privacidad de nuestros usuarios.</li>
            <li>Fomento de la educación financiera para empoderar a nuestros usuarios.</li>
            <li>Colaboración y respeto en todas las relaciones comerciales.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Equipo SVFinancex</h2>
          <p>
            Nuestro equipo está compuesto por profesionales altamente calificados en los campos financiero y
            tecnológico. Trabajamos arduamente para ofrecer una experiencia de alta calidad y apoyar a nuestros
            usuarios en su viaje hacia el éxito financiero.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Visión</h2>
          <p>
            En SVFinancex, aspiramos a ser líderes en la transformación de la forma en que las personas
            participan en los mercados financieros. Buscamos construir una comunidad global donde la
            colaboración y el conocimiento se combinen para crear oportunidades financieras accesibles para todos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Nuestros Servicios</h2>
          <p>
            Ofrecemos una variedad de servicios diseñados para satisfacer las necesidades de traders y
            usuarios sin experiencia. Desde cuentas gestionadas hasta herramientas de educación financiera,
            estamos aquí para ayudarte a alcanzar tus metas financieras.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Estadísticas de SVFinancex</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Usuarios Totales</h3>
              <p className="text-3xl font-bold">+1.500</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Traders Profesionales</h3>
              <p className="text-3xl font-bold">+5</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Operaciones Exitosas</h3>
              <p className="text-3xl font-bold">+2.000</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Rentabilidad Media</h3>
              <p className="text-3xl font-bold">+15%</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Cuentas Gestionadas</h3>
              <p className="text-3xl font-bold">+1.500</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Países Atendidos</h3>
              <p className="text-3xl font-bold">+50</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Inversionistas Satisfechos</h3>
              <p className="text-3xl font-bold">91%</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-teal-300 font-bold text-xl mb-2">Total De Afiliados</h3>
              <p className="text-3xl font-bold">-</p>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Preguntas Frecuentes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="bg-gray-800 p-4 rounded-md">
              <h3 className="text-teal-300 font-bold mb-2">¿Cómo puedo depositar fondos en mi cuenta?</h3>
              <p>
                Contacta con un trader y depositale el monto de la inversión que usted vaya a invertir.
              </p>
            </div>
            <div className="bg-gray-800 p-4 rounded-md">
              <h3 className="text-teal-300 font-bold mb-2">¿Cuál es el proceso para retirar ganancias?</h3>
              <p>
                Retirar ganancias es sencillo. Contacta con el trader asignado o selecionado y en un plazo maximo de 24 horas tendrá el deposito en su metodo de pago.
              </p>
            </div>
            <div className="bg-gray-800 p-4 rounded-md">
              <h3 className="text-teal-300 font-bold mb-2">¿Puedo cambiar mi trader asignado?</h3>
              <p>
                Sí, tienes la flexibilidad de cambiar tu trader asignado en cualquier momento. Simplemente
                comunícate con nuestro equipo de soporte y te guiarán a través del proceso.
              </p>
            </div>
            {/* Agrega más preguntas frecuentes según sea necesario */}
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Testimonios de UsuarioS</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="bg-gray-800 p-4 rounded-md">
              <p className="text-teal-300 font-bold">Julian</p>
              <p>
                "SVFinancex ha sido crucial en mi aprendizaje sobre trading. La plataforma es fácil de usar
                y los traders profesionales han mejorado significativamente mis resultados."
              </p>
            </div>
            <div className="bg-gray-800 p-4 rounded-md">
              <p className="text-teal-300 font-bold">Philip</p>
              <p>
                "La comunidad de SVFinancex me ha brindado una valiosa red de apoyo. Aprecio la transparencia
                y la diversidad de oportunidades que ofrece la plataforma."
              </p>
            </div>
                <div className="bg-gray-800 p-4 rounded-md">
              <p className="text-teal-300 font-bold">Lorena s.</p>
              <p>
                "La plataforma SVFinancex me ha permitido diversificar mis inversiones de manera efectiva.
                Los resultados han superado mis expectativas, y la transparencia en cada transacción es un gran
                punto a favor."
              </p>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Únete a Nosotros</h2>
          <p>
            Descubre las oportunidades que SVFinancex tiene para ofrecer. Únete a nuestra comunidad y
            comienza tu viaje hacia el éxito financiero hoy mismo.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold mb-4 text-teal-300">Contáctanos</h2>
          <p>
            ¿Tienes más preguntas? Estamos aquí para ayudarte. Ponte en contacto con nuestro equipo de
            soporte en support@svfinancex.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Nosotros;
